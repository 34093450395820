import React, { Fragment, useState, useEffect } from "react";
import { Card, Table, Input, Space, Button, message, Alert, Affix, Tabs, Tag } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Text from "antd/lib/typography/Text";
import EditField from './editField';
import {
  hideMessage,
  acceptRegistrationForm,
  removeRegistrationForm,
  removeBanRegistrationForm,
  //userTwitterSignIn
} from "appRedux/actions/Company";

import { useDispatch, useSelector } from "react-redux";

const { TabPane } = Tabs;
const showMessageUi = ({ type, content }) => {
  message[type](content);
};
//const Context = React.createContext({ name: 'Default' });
const Simple = (props) => {
  const dispatch = useDispatch();
  const { registrationRequest, loading, isAdmin } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //const [selectedNRowKeys, setSelectedNRowKeys] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [messsageApi, contextHolder] = message.useMessage();
  const { loader, alertMessage, showMessage } = useSelector(({ company }) => company);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState([]);
  console.log(useSelector(({ company }) => company));

  useEffect(() => {
    //showMessageUi({ type: 'error', content: alertMessage });
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
    }
  });

  /* if (showMessage) {
     
     let $timer = setTimeout(() => {
       clearTimeout($timer)
       $timer = null
       messsageApi.open({
       type: 'error',
       content: alertMessage,
     });
     }, 0)
   }*/
  const newReq = registrationRequest && isAdmin && !loading ? registrationRequest.map((item) => {
    return {
      ...item,
      key: item.id
      /*reqAccept: props.requestAccept,
      reqDelete: props.requestDelete */
    }
  }) : []
  const onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: changableRowKeys => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: changableRowKeys => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          /*ref={node => {
            this.searchInput = node;
          }}*/
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        //setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  console.log(selectedTab)
  const columns = [
    {
      title: 'Type',
      dataIndex: 'permitions',
      key: 'permitions',
      sorter: (a, b) => ('' + a.permitions).localeCompare(b.permitions),
      ...getColumnSearchProps('permitions'),
      render: permitions => (
        <>
          {
            permitions === 'Seller' ?
              <Tag color={'green'}>
                {permitions}
              </Tag> : null
          }
          {
            permitions === 'Customer' ?
              <Tag color={'geekblue'}>
                {permitions}
              </Tag> : null
          }
        </>
      ),
    },
    {
      title: 'Company name',
      dataIndex: 'company_name',
      key: 'company_name',
      sorter: (a, b) => ('' + a.company_name).localeCompare(b.company_name),
      ...getColumnSearchProps('company_name'),
      //render: text => <span className="gx-link">{text}</span>,
      
      render: (company_name, item) => {
        return <EditField
          permitions={item.permitions}
          //disabled={!company_name ? true : false}
          keyId={item.id}
          companyID={item.key}
          isAdmin={isAdmin}
          fieldValue={company_name}
          fieldName={'company_name'} />
      }
    },
    {
      title: 'VAT',
      dataIndex: 'VAT_n',
      key: 'VAT_n',
      sorter: (a, b) => ('' + a.VAT_n).localeCompare(b.VAT_n),
      ...getColumnSearchProps('VAT_n'),
      render: (VAT_n, item) => {
        return <EditField
          permitions={item.permitions}
          //disabled={!VAT_n ? true : false}
          keyId={item.id}
          companyID={item.key}
          isAdmin={isAdmin}
          fieldValue={VAT_n}
          fieldName={'VAT_n'} />
      }
    },
    {
      title: 'Adsress',
      dataIndex: 'address_string',
      key: 'address_string',
      sorter: (a, b) => ('' + a.address_string).localeCompare(b.address_string),
      ...getColumnSearchProps('address_string'),
      render: (address_string, item) => {
        return <EditField
          permitions={item.permitions}
          //disabled={!address_string ? true : false}
          keyId={item.id}
          companyID={item.key}
          isAdmin={isAdmin}
          fieldValue={address_string}
          fieldName={'address_string'} />
      }
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => ('' + a.email).localeCompare(b.email),
      ...getColumnSearchProps('email'),
      render: (email, item) => {
        return <EditField
          permitions={item.permitions}
          //disabled={!email ? true : false}
          keyId={item.id}
          companyID={item.key}
          isAdmin={isAdmin}
          fieldValue={email}
          fieldName={'email'} />
      }
    },
    {
      title: 'Website',
      dataIndex: 'company_URL',
      key: 'company_URL',
      //<Link href="#components-anchor-demo-static" title="Static demo" />
      render: (company_URL, item) => {
        return <EditField
          permitions={item.permitions}
          //disabled={!company_URL ? true : false}
          keyId={item.id}
          companyID={item.key}
          isAdmin={isAdmin}
          fieldValue={company_URL}
          fieldName={'company_URL'} />
      },
      ...getColumnSearchProps('company_URL')
    },
    {
      title: 'Code',
      dataIndex: 'company_code',
      key: 'company_code',
      sorter: (a, b) => a.company_code - b.company_code,
      ...getColumnSearchProps('company_code'),
      render: (company_code, item) => {
        return <EditField
          permitions={item.permitions}
          //disabled={!company_code ? true : false}
          keyId={item.id}
          companyID={item.key}
          isAdmin={isAdmin}
          fieldValue={company_code}
          fieldName={'company_code'} />
      }
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      sorter: (a, b) => ('' + a.country).localeCompare(b.country),
      ...getColumnSearchProps('country'),
      render: (country, item) => {
        return <EditField
          permitions={item.permitions}
          //disabled={!country ? true : false}
          keyId={item.id}
          companyID={item.key}
          isAdmin={isAdmin}
          fieldValue={country}
          fieldName={'country'} />
      }
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      sorter: (a, b) => ('' + a.language).localeCompare(b.language),
      ...getColumnSearchProps('language'),
      render: (language, item) => {
        return <EditField
          permitions={item.permitions}
          //disabled={!language ? true : false}
          keyId={item.id}
          companyID={item.key}
          isAdmin={isAdmin}
          fieldValue={language}
          fieldName={'language'} />
      }
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: phone => <Text>{phone ? (typeof phone === 'string') ? phone : `(${phone.code})${phone.number}` : ' '}</Text>
    },
    {
      title: 'Created',
      dataIndex: 'timestamp',
      sorter: (a, b) => ('' + a.timestamp).localeCompare(b.timestamp),
      key: 'timestamp',
      /* sorter: (a, b) => ('' + a.stockConfig).localeCompare(b.stockConfig),
       ...getColumnSearchProps('stockConfig'),*/
      render: (timestamp) => {
        //    console.log("orderItems")
        //    console.log(timestamp)

        return {
          props: {
            style: { background: '#eee' },
          },
          children: (
            <Text>{timestamp.toDate().toDateString()} {timestamp.toDate().toLocaleTimeString('lv-LV')}</Text>
          )
        }
      }
    },
    /* {
       title: 'Email verified',
       dataIndex: 'emailVerified',
       key: 'emailVerified',
       render: (text) => {
         //console.log(text);
         return text?(<i style={{fontSize: 24,color: 'limegreen'}} className="icon icon-check-cricle"/>):(<i style={{fontSize: 24,color: 'red'}} className="icon icon-close-circle"/>)
       }
     },*/
    /*{
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
        <span className="gx-link" onClick={()=>{ConfirmModal(record.key, `To create account for ${record.comName} on ${record.email}.`, (uid)=>{record.reqAccept(uid)})}}>Accept</span>
        <Divider type="vertical"/>
        <span className="gx-link" onClick={()=>{ConfirmModal(record.key, `To delete request from ${record.email}.`, (uid)=>{record.reqDelete(uid)})}}>Delete</span>
        <Divider type="vertical"/>
        <span className="gx-link ant-dropdown-link">
          More actions <Icon type="down"/>
        </span>
      </span>
      ),
    }*/
  ];
  const reqName = selectedRowKeys.length > 1 ? 'requests' : 'request';

  return (
    <Fragment>
      {showMessage ?
        <Affix offsetTop={15} >
          <Alert message={alertMessage.message} type={alertMessage.type} showIcon />
        </Affix>
        : null}
      <Card title={"Registration"} loading={!registrationRequest}>

        <Tabs defaultActiveKey='1' onChange={(tabN) => setSelectedTab(tabN)}>
          <TabPane tab="Complited" key="1">
            <Table rowSelection={rowSelection} className="gx-table-responsive" loading={loader || loading} columns={columns} dataSource={newReq.filter(({ complete, disabled }) => complete && !disabled)} />
            {

              selectedRowKeys.length > 0
                ?
                <Fragment>
                  <Button type="primary" onClick={() => dispatch(acceptRegistrationForm({
                    ids: selectedRowKeys.map(id => ({ id, permitions: newReq.find(item => item.id === id).permitions }))
                  }))}>Accept {reqName}</Button>
                  <Button type='dashed' onClick={() => dispatch(removeBanRegistrationForm({
                    ids: selectedRowKeys
                  }))}>Remove {reqName} and ban user</Button>
                  <Button type='dashed' onClick={() => dispatch(removeRegistrationForm({
                    ids: selectedRowKeys
                  }))}>Remove {reqName}</Button>
                </Fragment>
                :
                <Fragment>
                  <Button type="primary" disabled>Accept {reqName}</Button>
                  <Button type='dashed' disabled>Remove {reqName} and ban user</Button>
                  <Button type='dashed' disabled>Remove {reqName}</Button>
                </Fragment>
            }
          </TabPane>
          <TabPane tab="Not complited" key="2">
            <Table rowSelection={rowSelection} className="gx-table-responsive" loading={loader || loading} columns={columns} dataSource={newReq.filter(({ complete, disabled }) => !complete && !disabled)} />
            {
              selectedRowKeys.length > 0
                ?
                <Fragment>
                  <Button type="primary" onClick={() => dispatch(acceptRegistrationForm({
                    ids: selectedRowKeys.map(id => ({ id, permitions: newReq.find(item => item.id = id).permitions }))
                  }))}>Accept {reqName}</Button>
                  <Button type='dashed' onClick={() => dispatch(removeBanRegistrationForm({
                    ids: selectedRowKeys
                  }))}>Remove {reqName} and ban user</Button>
                  <Button type='dashed' onClick={() => dispatch(removeRegistrationForm({
                    ids: selectedRowKeys
                  }))}>Remove {reqName}</Button>
                </Fragment>
                :
                <Fragment>
                  <Button type="primary" disabled>Accept {reqName}</Button>
                  <Button type='dashed' disabled>Remove {reqName} and ban user</Button>
                  <Button type='dashed' disabled>Remove {reqName}</Button>
                </Fragment>
            }
          </TabPane>
          <TabPane tab="Disabled" key="3">
            <Table rowSelection={rowSelection} className="gx-table-responsive" loading={loader || loading} columns={columns} dataSource={newReq.filter(({ disabled }) => disabled)} />
          </TabPane>
        </Tabs>
      </Card>
    </Fragment>
  );
};

export default Simple;