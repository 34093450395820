import {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_FULL_PRODUCT,
  EDIT_FULL_PRODUCT_SUCCESS,
  SHOW_MESSAGE,
  HIDE_MESSAGE
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  data: null
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EDIT_PRODUCT_SUCCESS: {
      return {
        ...state,
        loader: false,
        //data: action.payload
      }
    }
    case EDIT_PRODUCT: {
      return {
        ...state,
        loader: true,
        //data: action.payload
      }
    }
    case EDIT_FULL_PRODUCT_SUCCESS: {
      return {
        ...state,
        loader: false,
        //data: action.payload
      }
    }
    case EDIT_FULL_PRODUCT: {
      return {
        ...state,
        loader: true,
        //data: action.payload
      }
    }
    case ADD_PRODUCT_SUCCESS: {
      return {
        ...state,
        loader: false,
        //data: action.payload
      }
    }
    case ADD_PRODUCT: {
      return {
        ...state,
        loader: true,
        //data: action.payload
      }
    }
    case DELETE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loader: false,
        //data: action.payload
      }
    }
    case DELETE_PRODUCT: {
      return {
        ...state,
        loader: true,
        //data: action.payload
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    default:
      return state;
  }
}
