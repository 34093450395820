import React from "react";

import EditRequest from "./EditRequest";
import ReqList from "./ReqList";
import CompanyInfo from "./CompanyInfo";
import Auxiliary from "util/Auxiliary";
import {Col, Row} from "antd";
import { connect } from "react-redux";
 
const SamplePage = (props) => {
  const { isAdmin } = props;
  return (
    <div>
      <h2 className="title gx-mb-4">{/*<IntlMessages id="sidebar.samplePage"/>*/}</h2>
      
      <div className="gx-d-flex justify-content-center">
        {/*<h4>Under construction. Here dummy data only!</h4>*/}
      </div>
      <Auxiliary>
        <Row>
          <Col span={24}>
            {isAdmin? <ReqList {...props}/> : <EditRequest {...props}/>}
          </Col>
        </Row>
      </Auxiliary>
    </div>
  );
};

const mapStateToProps = ({/*auth*/firebase: { auth }, firestore: { ordered, data, status: { requesting } }, firestore }) => {
  console.log(firestore);
  const { uid } = auth;
  const { isAdmin } = data;
  const { registrationRequest } = ordered;
  //console.log(registrationRequest);
  const loading = uid && requesting.registrationRequest && isAdmin;
  return {
    isAdmin,
    registrationRequest,
    loading
  }
}
/*
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      requestAccept: RequestsActions.requestAccept,
      requestDelete: RequestsActions.requestDelete
    }, dispatch)
}*/

export default connect(mapStateToProps/*, mapDispatchToProps*/)(SamplePage);