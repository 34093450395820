import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore' // <- needed if using firestore
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/database'

// Initialize Firebase
const config = {
  apiKey: "AIzaSyBUNoyU2pgj_B78Ye7Qkqj21e5YCFWLwAM",
  authDomain: "xpall-218fb.firebaseapp.com",
  databaseURL: "https://xpall-218fb.firebaseio.com",
  projectId: "xpall-218fb",
  storageBucket: "xpall-218fb.appspot.com",
  messagingSenderId: "842974888538",
  appId: "1:842974888538:web:e60cfd68b9447cb26c4e63",
  measurementId: "G-CZX526J68K"
};

firebase.initializeApp(config);
const auth = firebase.auth();
const functions = firebase.functions();
const firestore = firebase.firestore();
const storage = firebase.storage();
const database = firebase.database();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
//const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
//const githubAuthProvider = new firebase.auth.GithubAuthProvider();
//const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  auth,
  googleAuthProvider,
  functions,
  firestore,
  firebase,
  storage,
  database
 // githubAuthProvider,
 // facebookAuthProvider,
  //twitterAuthProvider
};
export default firebase;