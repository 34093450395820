import React, { Fragment, useState, useEffect } from "react";
import { Card, Table, Input, Space, Button, message, Badge, Typography, Alert, Affix, Select, Tag, Spin, Form, InputNumber } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { arrayMoveImmutable } from 'array-move';
import { MenuOutlined } from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
//import Text from "antd/lib/typography/Text";
import {
  hideMessage,
  setVariables
  //acceptRegistrationForm,
  //userTwitterSignIn
} from "appRedux/actions/Variables";
import { useDispatch, useSelector } from "react-redux";

const { Text } = Typography;
const { Option } = Select;
const FormItem = Form.Item;


//part this thing
//
const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const showMessageUi = ({ type, content }) => {
  message[type](content);
};
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);
const columns = [
  {
    title: 'Sort',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    className: 'drag-visible',
  },
];
//const Context = React.createContext({ name: 'Default' });
const Simple = (props) => {
  const { loading, isAdmin, marketSettings } = props;
  const [isEdited, setIsEdited] = useState(false);
  const [dataSource, setDataSource] = useState(marketSettings?marketSettings.PaletteTypes:[].map((name, key)=>({
    key,
    name
  })));
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage } = useSelector(({ variables }) => variables);
  const [form] = Form.useForm();
  //console.log(useSelector(({ company }) => company));
  useEffect(() => {
    //showMessageUi({ type: 'error', content: alertMessage });
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
    }
  });
  const onEdit = () => {
    if (!isEdited) {
      setIsEdited(true);
    }
  };
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
   // const { dataSource } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      console.log('Sorted items: ', newData);
      setDataSource(newData);
      //this.setState({ dataSource: newData });
    }
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
//    const { dataSource } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
//    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={0} {...restProps} />;
  };
  return (
    <Fragment>
      {/*showMessage ?
        <Affix offsetTop={15} >
          <Alert message={alertMessage.message} type={alertMessage.type} showIcon />
        </Affix>
      : null*/}
      <Card title={"Market settings"} loading={!marketSettings}>

        <Space size='large' direction="vertical" style={{ width: "100%" }}>
          {isAdmin && marketSettings
            ?
            <Spin spinning={loader}>
              <Form
                name="basic"
                form={form}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0"
                initialValues={{
                  km_price_eur: marketSettings[0].km_price_eur,
                  commission: marketSettings[0].commission
                }}
                >
                <FormItem label="Сommission per pallette" name="commission">
                  <InputNumber
                    min={0}
                    step={0.01}
                    formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g)}
                    //parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={onEdit}
                  />
                </FormItem>
                <FormItem label="KM price" name="km_price_eur">
                  <InputNumber
                    formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g)}
                    min={0}
                    step={0.5}
                    //parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={onEdit}
                  />
                </FormItem>
                <Table
                  pagination={false}
                  dataSource={dataSource}
                  columns={columns}
                  rowKey="index"
                  components={{
                    body: {
                      wrapper: DraggableContainer,
                      row: DraggableBodyRow,
                    },
                  }}
                />
                <Button disabled={!isEdited} type="primary" onClick={async () => {
                  try {
                    let validation = null;
                    validation = await form.validateFields();
                    dispatch(setVariables({
                      ...validation
                    }))
                  }
                  catch {
                    message.warning('Error! 23');
                  }
                }}>Save</Button>
                <Button type='dashed' onClick={() => {
                  setIsEdited(false);
                  form.resetFields();
                }}>Reset</Button>
              </Form>
            </Spin>

            : null}
        </Space>
      </Card>
    </Fragment>
  );
};

export default Simple;