import {
  ORDER_STATUS_CHANGE_SUCCESS,
  ORDER_STATUS_CHANGE,
  ORDER_FIELD_CHANGE_SUCCESS,
  ORDER_FIELD_CHANGE,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  ORDER_ADDITIONAL_PAYMENT_CHANGE_SUCCESS,
  ORDER_ADDITIONAL_PAYMENT_CHANGE,
  ORDER_INVOICE_CHANGE,
  ORDER_INVOICE_CHANGE_SUCCESS,
  SHOW_MESSAGE,
  HIDE_MESSAGE
} from "constants/ActionTypes";


export const removeOrder = (data) => {
  return {
    type: DELETE_ORDER,
    payload: data
  };
};
export const removeOrderSuccess = (data) => {
  return {
    type: DELETE_ORDER_SUCCESS,
    payload: data
  };
};
export const setOrderInvoice = (data) => {
  return {
    type: ORDER_INVOICE_CHANGE,
    payload: data
  };
};
export const setOrderInvoiceSuccess = (data) => {
  return {
    type: ORDER_INVOICE_CHANGE_SUCCESS,
    payload: data
  };
};
export const setOrderStatus = (data) => {
  return {
    type: ORDER_STATUS_CHANGE,
    payload: data
  };
};
export const setOrderStatusSuccess = (data) => {
  return {
    type: ORDER_STATUS_CHANGE_SUCCESS,
    payload: data
  };
};
export const setOrderField = (data) => {
  return {
    type: ORDER_FIELD_CHANGE,
    payload: data
  };
};
export const setOrderFieldSuccess = (data) => {
  return {
    type: ORDER_FIELD_CHANGE_SUCCESS,
    payload: data
  };
};
export const setOrderAdditionalPayment = (data) => {
  return {
    type: ORDER_ADDITIONAL_PAYMENT_CHANGE,
    payload: data
  };
};
export const setOrderAdditionalPaymentSuccess = (data) => {
  return {
    type: ORDER_ADDITIONAL_PAYMENT_CHANGE_SUCCESS,
    payload: data
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


