import React from "react";
import { Divider, Menu } from "antd";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";


import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const SidebarContent = (props) => {

  let { navStyle, themeType, pathname } = useSelector(({ settings }) => settings);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  const { userData, isAdmin, myCompanies } = props;
  //console.log(userData);
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile {...userData} />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">
            <MenuItemGroup key="botg_maintance" className="gx-menu-group" title={isAdmin ? 'XPall Superadmin' : myCompanies ? "Company" : "Setup"}>
              {
                isAdmin ? <Menu.Item key="variables">
                  <Link to="/variables"><i className="icon icon-select" />
                    {"Variables"}</Link>
                </Menu.Item> : null
              }
              <Menu.Item key={myCompanies && !isAdmin ? "my_company" : "requests"}>
                <Link to={myCompanies && !isAdmin ? "/my_company" : "/requests"}><i className={myCompanies && !isAdmin ? "icon icon-company" : "icon icon-editor"} />
                  {isAdmin ? "XPALL requests" : "My company"}{/*<IntlMessages id="sidebar.general.mycompany"/>*/}</Link>
              </Menu.Item>
              {
                isAdmin ? <Menu.Item key="companies">
                  <Link to="/companies"><i className="icon icon-company" />
                    {"Companies"}{/*<IntlMessages id="sidebar.general.mycompany"/>*/}</Link>
                </Menu.Item> : null
              }
            </MenuItemGroup>
            {
              isAdmin || myCompanies
                ?
                <MenuItemGroup
                  key="main"
                  className="gx-menu-group"
                  title={
                    'Main'
                  }>
                  <Menu.Item key="store">
                    <Link to="/store"><i className="icon icon-shopping-cart" />
                      {isAdmin ? "Store" : 'My store'}
                      {/*<IntlMessages id="sidebar.general.mycompany"/>*/}
                    </Link>
                  </Menu.Item>
                  {
                    isAdmin?
                    <Menu.Item key="orders">
                      <Link to="/orders"><i className="icon icon-orders" />
                        Orders
                        {/*<IntlMessages id="sidebar.general.mycompany"/>*/}
                      </Link>
                    </Menu.Item>
                    :null
                  }
                </MenuItemGroup>
                : null
            }
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
const mapStateToProps = ({/*auth*/firebase: { auth }, settings, firestore: { ordered, data }, firebase }) => {
  //console.log(ordered);
  //const uid = auth.authUser;
  const { uid, providerData } = auth;
  const { isAdmin, myCompanies, registrationRequest } = data;
  //console.log(data);
  //console.log(firebase);
  //console.log(auth);
  const userInfo = uid && providerData.length > 0 ? providerData[0] : null
  const userData = {
    ...userInfo,
    uid
  }
  /*const mainUser = ordered.mainUser?ordered.mainUser[0]:null;
  const companies = (ordered.myCompanies||[]).map((comItem)=>{
    return {
      key: comItem.id,
      name: comItem.name
    };
  });
  const employers = ordered.accountEmployers||[];
  const mainEmployer = companySelector.companySelected?(employers.find((emItem)=>(uid+companySelector.companySelected.id)===emItem.id)  ||null):null;
  const selectedCompany = companySelector.companySelected?(companies.find((comItem)=>companySelector.companySelected.id===comItem.id)||null):null;*/
  //const {navStyle, themeType, locale, pathname} = settings;
  return {
    userData,
    isAdmin,
    myCompanies,
    registrationRequest
    /*navStyle, 
    themeType, 
    locale, 
    pathname, 
    mainUser, 
    companies, 
    mainEmployer,
    selectedCompany*/
  }
};
/*
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      requestAccept: RequestsActions.selectCompany,
    }, dispatch)
}*/

export default connect(mapStateToProps/*, mapDispatchToProps*/)(SidebarContent);

