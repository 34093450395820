import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";
import { Spin, Space, Button } from 'antd';

import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import { setInitUrl } from "appRedux/actions/Auth";
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";

import Axios from "axios";
import { connect } from "react-redux";
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authUser
          ? <Component {...props} />
          : <Redirect
            to={{
              pathname: '/signin',
              state: { from: location }
            }}
          />}
    />)
};

const PreviewPdf = ({id}) => {
  const [link, setLink] = useState(null);
  const [err, setErr] = useState(null);
  //const get_data = async () => {
    /*https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%2F1611438603843?alt=media&token=a24e1a94-ca22-4ff7-bcd5-8a4f0c16e6de */
    /*https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%2F1611438603843*/
    Axios.get(`https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}`).then(data=>{
      console.log('--------good----------');
      console.log(data);
      const generatedLink = `https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.data.downloadTokens}`;
      console.log(generatedLink);
    /*  Axios.get({
        url: `https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.data.downloadTokens}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        console.log(response);
      })*/
      setLink(generatedLink);
      //console.log(`https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.downloadTokens}`);
    }).catch(e=>{
      console.log('--------error-------------')
      console.log(e);
      setErr('Invoice dont exist');
    })
 // }
 // get_data();
  return link?
  <div style={{marginTop: 20}}>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
      <Button href={link} type="link">Download</Button>
    <Viewer fileUrl={`https://cors-anywhere.herokuapp.com/${link}`} />
  </Worker>
  </div>
  :err?<div>{err}</div>:<Spin size="large" />
}
const DownloadPdf = ({id}) => {
  const [link, setLink] = useState(null);
  const [err, setErr] = useState(null);
  //const get_data = async () => {
    /*https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%2F1611438603843?alt=media&token=a24e1a94-ca22-4ff7-bcd5-8a4f0c16e6de */
    /*https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%2F1611438603843*/
    Axios.get(`https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}`).then(data=>{
      console.log('--------good----------');
      console.log(data);
      const generatedLink = `https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.data.downloadTokens}`;
      console.log(generatedLink);
    /*  Axios.get({
        url: `https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.data.downloadTokens}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        console.log(response);
      })*/
      setLink(generatedLink);
      //console.log(`https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.downloadTokens}`);
    }).catch(e=>{
      console.log('--------error-------------')
      console.log(e);
      setErr('Invoice dont exist');
    })
 // }
 // get_data();
  return link?
  <div style={{marginTop: 20}}>
      <Button href={link} type="link">Download</Button>
  </div>
  :err?<div>{err}</div>:<Spin size="large" />
}

const App = (props) => {

  const dispatch = useDispatch();
  const { locale, themeType, navStyle, layoutType } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();


  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  });


  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser === null) {
        history.push('/signin');
      } else if (authUser === null && (initURL==='/getInvoice/' || initURL==='/downloadInvoice/')){
        console.log('PDF---------')
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        history.push('/main/dashboard');
      } else {
        history.push(initURL);
      }
    }
  }, [authUser, initURL, location, history]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      console.log("adding dark class")
      document.body.classList.add('dark-theme');
      document.body.classList.add('dark-theme');
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = "/css/dark_theme.css";
      link.className = 'style_dark_theme';
      document.body.appendChild(link);
    }
  }
    , []);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>

        <Switch>

          <Route exact path="/getInvoice/:id" component={({ match: { params: { id } } }) => {
            return <PreviewPdf id={id} />
          }} />
          <Route exact path="/downloadInvoice/:id" component={({match: {params: {id}}}) => {
            return <DownloadPdf id={id}/>
          }} />
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/signup' component={SignUp} />
          <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location}
            component={MainApp} />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
};


const mapStateToProps = (state) => {
  //const {width, navStyle} = state.settings;
  const { auth } = state;
  //console.log(state);
  //const {mainUser} = state.firestore.ordered;
  //const accountUser = mainUser?mainUser[0]:null;
  return {
    //width, 
    //navStyle, 
    auth,
    //accountUser
  };
};
export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const { auth } = props;
    console.log("indexrebuild");
    //console.log(props);
    const uid = auth.authUser;
    return uid ? [
      {
        collection: 'Admin',
        doc: uid,
        storeAs: 'isAdmin'
      },
    ] : []
  })
)(memo(App));
