import React, { useState } from 'react';
import { Modal, Button, Typography, Tooltip } from 'antd';
import MessageField from './SellerCommentToCustomer';
import {
    CommentOutlined
  } from '@ant-design/icons';
const { Title, Text } = Typography;

const CommentButton = ({ item, keyId, orderId }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { customerComment } = item;
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    console.log("+++++++++++++++++++++++");
    console.log(item);
    return (
        <>
            <Tooltip placement="top" title={customerComment ? customerComment : 'No comments from user'}>
                <Button type="primary" onClick={showModal}>
                    <CommentOutlined />
                </Button>
            </Tooltip>
            <Modal title="Messages"  visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Title level={4}>Comment from customer:</Title>
                {<Text disabled={customerComment ? false : true} >{customerComment ? customerComment : "no messages here"}</Text>}
                <Title level={4}>Message to customer</Title>
                <MessageField value={item.sellerComment} keyId={keyId} orderId={orderId}/>
            </Modal>
        </>
    );
};
export default CommentButton;