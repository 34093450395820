import {
  END_COMPANY_REGISTRATION,
  END_COMPANY_REGISTRATION_SUCCESS,
  ACCEPT_REGISTRATION,
  ACCEPT_REGISTRATION_SUCCESS,
  REJECT_REGISTRATION,
  REJECT_REGISTRATION_SUCCESS,
  REJECT_BAN_REGISTRATION,
  REJECT_BAN_REGISTRATION_SUCCESS,
  COMPANY_FIELD_CHANGE,
  COMPANY_FIELD_CHANGE_SUCCESS,
  SHOW_MESSAGE,
  HIDE_MESSAGE
} from "constants/ActionTypes";

export const companyFieldChange = (data) => {
  return {
    type: COMPANY_FIELD_CHANGE,
    payload: data
  };
};
export const companyFieldChangeSuccess = (data) => {
  return {
    type: COMPANY_FIELD_CHANGE_SUCCESS,
    payload: data
  };
};
export const endRegistration = (data) => {
  return {
    type: END_COMPANY_REGISTRATION,
    payload: data
  };
};
export const endRegistrationSuccess = (data) => {
  return {
    type: END_COMPANY_REGISTRATION_SUCCESS,
    payload: data
  };
};
export const acceptRegistrationForm = (data) => {
  return {
    type: ACCEPT_REGISTRATION,
    payload: data
  };
};
export const acceptRegistrationFormSuccess = (data) => {
  return {
    type: ACCEPT_REGISTRATION_SUCCESS,
    payload: data
  };
};
export const removeRegistrationForm = (data) => {
  return {
    type: REJECT_REGISTRATION,
    payload: data
  };
};
export const removeRegistrationFormSuccess = (data) => {
  return {
    type: REJECT_REGISTRATION_SUCCESS,
    payload: data
  };
};
export const removeBanRegistrationForm = (data) => {
  return {
    type: REJECT_BAN_REGISTRATION,
    payload: data
  };
};
export const removeBanRegistrationFormSuccess = (data) => {
  return {
    type: REJECT_BAN_REGISTRATION_SUCCESS,
    payload: data
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


