import React, { useState, useEffect } from "react";
import { Button, Checkbox, Divider, Form, Input, Modal, Tooltip, Select } from "antd";
import PhoneCodeData from "../assets/misc/PhoneCodes";
import { Tabs } from 'antd';
import Icon from '@ant-design/icons';
import MapPicker from '../components/MapPicker/MapPicker';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  //userFacebookSignIn,
  //userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  //userTwitterSignIn
} from "../appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";
import CircularProgress from "components/CircularProgress/index";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
/*
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
*/
const { Option } = Select;
const FormItem = Form.Item;
const InputGroup = Input.Group;
const { confirm } = Modal;
const { TabPane } = Tabs;

function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (value) {
      if (value.charAt(value.length - 1) === '.' || value === '-') {
        valueTemp = value.slice(0, -1);
      }
      onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    }
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const { value } = this.props;
    const title = value ? (
      <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    ) : (
        'Input a number'
      );
    return (
      <Tooltip
        trigger={['focus']}
        title={title}
        placement="topLeft"
        overlayClassName="numeric-input"
      >
        <Input
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder="Input a number"
          maxLength={25}
        />
      </Tooltip>
    );
  }
}


const SignUp = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  /*useResetFormOnCloseModal({
    form,
    visible,
  });*/

  const onOk = () => {
    form.submit();
  };
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      console.log("values", values);
      if (!err) {
        dispatch(showAuthLoader());
        dispatch(userSignUp(values));
      }
    });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    /* confirm({
       title: 'Verification.',
       content: 'We will send e-mail for verification.',
       onOk: () => {
         console.log('OK');*/
    dispatch(showAuthLoader());
    const data = {
      ...values,
      company_code: null,
      VAT_n: null,
      address_string: null,
      language: null,
      country: null,
      company_URL: null,
      phone: {
        code: '',
        number:''
      }
    }
    dispatch(userSignUp(data));
    //this.props.showAuthLoader();
    //this.props.userSignUp(values);
    /*},
    onCancel() {
      console.log('Cancel');
    },
  });*/
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {/*<img src={"https://via.placeholder.com/272x395"} alt='Neature' />*/}
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.signUp" /></h1>
              <p><IntlMessages id="app.userAuth.bySigning" /></p>
              <p><IntlMessages id="app.userAuth.getAccount" /></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.png")} />
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              {/*<Tabs defaultActiveKey='1'>
                <TabPane tab="Company info" key="1">
                  <FormItem rules={[{ required: true, message: 'Please input company name!' }]} name="company_name">
                    <Input placeholder="Company name" />
                  </FormItem>
                  <FormItem rules={[{ required: true, message: 'Please input company code!' }]} name="company_code">
                    <Input placeholder="Company code" />
                  </FormItem>
                  <FormItem rules={[{ required: true, message: 'Please input valid VAT!' }]} name="VAT_n">
                    <Input placeholder="Valid VAT" />
                  </FormItem>
                  <FormItem rules={[{ required: true, message: 'Please input WEB site!' }]} name="company_URL">
                    <Input placeholder="Website" />
                  </FormItem>
                  <FormItem>

                    <InputGroup compact>

                      <FormItem
                        name={['phone', 'code']}
                        noStyle
                        rules={[{ required: true, message: 'Code is required!' }]}
                      >
                        <Select
                          showSearch
                          style={{ width: '30%' }}
                          placeholder="Select code"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }>
                          {Object.values(PhoneCodeData).map(({ code, name }, num) =>
                            <Option key={code + ' ' + num} value={code}>{`${code} ${name}`}</Option>
                          )}
                        </Select>
                      </FormItem>

                      <FormItem
                        name={['phone', 'number']}
                        noStyle
                        rules={[{ required: true, message: 'Number is required' }]}
                      >
                        <NumericInput style={{ width: '70%' }} placeholder="Number" />
                      </FormItem>

                    </InputGroup>
                  </FormItem>
                  <FormItem rules={[{ required: true, message: 'Please input valid VAT!' }]} name="address_string">
                    <Input placeholder="Address" />
                  </FormItem>
                </TabPane>
                <TabPane tab="Localization" key="2">
                  <FormItem rules={[{ required: true, message: 'Please select language!' }]} name="language">
                    <Select
                      showSearch
                      placeholder="Select language"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {Object.values(languages).map(({ native, name }) =>
                        <Option key={name} value={name}>{`${name} / ${native}`}</Option>
                      )}
                    </Select>
                  </FormItem>
                  <FormItem rules={[{ required: true, message: 'Please select country!' }]} name="country">
                    <Select
                      showSearch
                      placeholder="Select country"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {Object.values(countries).map(({ native, name }) =>
                        <Option key={name} value={name}>{`${name} / ${native}`}</Option>
                      )}
                    </Select>
                  </FormItem>
                </TabPane>
                <TabPane tab="For email registration" key="3">
                  <FormItem rules={[{ required: true, message: 'Please input your first name!' }]} name="first_name">
                    <Input placeholder="FirstName" />
                  </FormItem>
                  <FormItem rules={[{ required: true, message: 'Please input your last name!' }]} name="last_name">
                    <Input placeholder="LastName" />
                  </FormItem>
                  <FormItem name="email" rules={[{
                    required: true, type: 'email', message: 'The input is not valid E-mail!',
                  }]}>
                    <Input placeholder="Email" />
                  </FormItem>
                  <FormItem name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}>
                    <Input type="password" placeholder="Password" />
                  </FormItem>
                </TabPane>

              </Tabs>*/}
              <FormItem rules={[{ required: true, message: 'Please input company name!' }]} name="company_name">
                <Input placeholder="Company name" />
              </FormItem>
              <FormItem name="email" rules={[{
                required: true, type: 'email', message: 'The input is not valid E-mail!',
              }]}>
                <Input placeholder="Email" />
              </FormItem>
              <FormItem name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}>
                <Input type="password" placeholder="Password" />
              </FormItem>
              <FormItem name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
                <Link className="gx-login-form-forgot" to="/custom-views/user-auth/forgot-password">Forgot password</Link>
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signUp" />
                </Button>
                <span><IntlMessages id="app.userAuth.or" /></span> <Link to="/signin"><IntlMessages
                  id="app.userAuth.signIn" /></Link>
              </FormItem>

              <div className="gx-flex-row gx-justify-content-between">
                <span>or connect with</span>
                <ul className="gx-social-link">
                  <li>
                    <GoogleOutlined onClick={async () => {
                      // console.log(form);
                      const result = await form.validateFields([
                        "company_name",
                        /*"company_code",
                        "VAT_n",
                        "address_string",
                        "language",
                        "country",
                        "company_URL",
                        ['phone', 'code'],
                        ['phone', 'number']*/
                      ])
                      console.log(result);
                      /*if(result.err){
                        console.log("ERRRRRRRRRROOOR")
                      }*/
                      if (!result.err) {
                        console.log('OOOKKKKK');
                        console.log(result.values);
                        //dispatch(showAuthLoader());
                        dispatch(userGoogleSignIn(result));
                      }
                      /* const form.getFieldsValue([
                         "CompanyName",
                         "CompanyCode",
                         "VAT",
                         "Address",
                         "Language",
                         "Country",
                         'phone',
                       ])*/
                      /* await form.validateFields([
                         "CompanyName",
                         "CompanyCode",
                         "VAT",
                         "Address",
                         "Language",
                         "Country",
                         ['phone', 'code'],
                         ['phone', 'number']
                       ], ((err, values) => {
                         console.log("values", values);
                         if (!err) {
                           console.log('auth!!')
                           dispatch(showAuthLoader());
                           dispatch(userGoogleSignIn());
                         }
                       }));*/
                    }} />
                  </li>
                  {/*<li>
                    <FacebookOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userFacebookSignIn());
                    }}/>
                  </li>
                  <li>
                    <GithubOutlined  onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userGithubSignIn());
                    }}/>
                  </li>
                  <li>
                    <TwitterOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userTwitterSignIn());
                    }}/>
                  </li>*/}
                </ul>
              </div>
            </Form>
          </div>
          {loader &&
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          }
          {showMessage &&
            message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};


export default SignUp;
