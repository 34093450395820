import React from "react";

import Page from "./Page";
import Auxiliary from "util/Auxiliary";
import { Col, Row } from "antd";
import { connect } from "react-redux";

const SamplePage = (props) => {
  const { isAdmin, myCompanies, loading } = props;
  return (
    <div>
      <h2 className="title gx-mb-4">{!loading && myCompanies && !isAdmin ? 'Not permited' : null}{/*<IntlMessages id="sidebar.samplePage"/>*/}</h2>

      <div className="gx-d-flex justify-content-center">
        {/*<h4>Under construction. Here dummy data only!</h4>*/}
      </div>
      <Auxiliary>
        <Row>
          <Col span={24}>
            <Page {...props} />
          </Col>
        </Row>
      </Auxiliary>
    </div>
  );
};

const mapStateToProps = ({/*auth*/firebase: { auth }, firestore: { ordered, data, status: { requesting } }, firestore }) => {
  console.log(firestore);
  const { uid } = auth;
  const { isAdmin } = data;
  const myCompanies_s = ordered.myCompanies;
  const { myCompanies_c } = ordered;
  //console.log(registrationRequest);
 /* console.log("myCompanies_s");
  console.log(myCompanies_s);
  console.log("myCompanies_c");
  console.log(myCompanies_c);*/
  const loading = uid && requesting.myCompanies && requesting.myCompanies_c && isAdmin;
  if (myCompanies_c && myCompanies_s) {
    const myCompanies = [
      ...myCompanies_c.map(props => ({ ...props, permitions: 'Customer', key: props.id+'C' })), 
      ...myCompanies_s.map(props => ({ ...props, permitions: 'Seller', key: props.id+'S'  }))
    ];
    console.log("myCompanies");
    console.log(myCompanies);
    return {
      isAdmin,
      myCompanies,
      loading
    }
  }
  else {
    return {
      isAdmin,
      myCompanies: [],
      loading
    }
  }

}
/*
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      requestAccept: RequestsActions.requestAccept,
      requestDelete: RequestsActions.requestDelete
    }, dispatch)
}*/

export default connect(mapStateToProps/*, mapDispatchToProps*/)(SamplePage);