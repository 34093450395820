import React from "react";
 
import Page from "./Page";
import Auxiliary from "util/Auxiliary";
import {Col, Row} from "antd";

import {connect} from "react-redux";
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase';

const SamplePage = (props) => {
  const { isAdmin, products, loading } = props;
  return (
    <div>
      <h2 className="title gx-mb-4">{!loading&&products&&!isAdmin?'Not permited':null}{/*<IntlMessages id="sidebar.samplePage"/>*/}</h2>
      
      <div className="gx-d-flex justify-content-center">
        {/*<h4>Under construction. Here dummy data only!</h4>*/}
      </div>
      <Auxiliary>
        <Row>
          <Col span={24}>
            <Page {...props}/>
          </Col>
        </Row>
      </Auxiliary>
    </div>
  );
};

const mapStateToProps = ({/*auth*/firebase: { auth }, firestore: { ordered, data, status: { requesting } }, firestore }) => {
  
  const { uid } = auth;
  const { isAdmin } = data;
  const { myCompanies, products } = ordered;
  const { marketSettings } = data;
 //console.log('+++++++++++++++++++++++++++');
 //console.log(marketSettings);
  //console.log(products);
  const loading = uid && requesting.myCompanies && requesting.products && isAdmin;
  return {
    auth,
    isAdmin,
    myCompanies,
    products,
    loading,
    marketSettings
  }
} 
/*
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      requestAccept: RequestsActions.requestAccept,
      requestDelete: RequestsActions.requestDelete
    }, dispatch)
}*/

export default compose( 
  connect(mapStateToProps),
  firestoreConnect((props)=>{
    const {auth, isAdmin, myCompanies} = props;
    //const {authUser} = auth;
    const adminType = isAdmin?isAdmin.type:null;
    console.log("storerebuild");
    console.log(auth);
    const uid = auth?auth.uid:null;
    const companyID = uid&&myCompanies? 
    isAdmin? 'all': myCompanies.find(cmp=>cmp.access.includes(uid))
    :undefined;
    console.log(companyID);
    console.log(uid);
    console.log(myCompanies?myCompanies.find(cmp=>cmp.access.includes(uid)):null);
    //console.log(myCompanies);
    return companyID!==undefined?[
      adminType
      ?{
        collection: 'Product', 
        where: [
          ['disabled', '==', false],
        ],
        storeAs: 'products'
      }
      :{
        collection: 'Product', 
        where: [
          ['disabled', '==', false],
          ['companyID', '==', companyID],
          ['access', 'array-contains', uid],
        ],
        storeAs: 'products'
      }, 
    ]:[]
  })
)(SamplePage);