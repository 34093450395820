import {
  VARIABLES_CHANGE_SUCCESS,
  VARIABLES_CHANGE,
  SHOW_MESSAGE,
  HIDE_MESSAGE
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  data: null
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VARIABLES_CHANGE_SUCCESS: {
      return {
        ...state,
        loader: false
      }
    }
    case VARIABLES_CHANGE: {
      return {
        ...state,
        loader: true
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    default:
      return state;
  }
}
