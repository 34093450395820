import {
  ORDER_STATUS_CHANGE_SUCCESS,
  ORDER_STATUS_CHANGE,
  ORDER_FIELD_CHANGE_SUCCESS,
  ORDER_FIELD_CHANGE,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  ORDER_ADDITIONAL_PAYMENT_CHANGE_SUCCESS,
  ORDER_ADDITIONAL_PAYMENT_CHANGE,
  ORDER_INVOICE_CHANGE,
  ORDER_INVOICE_CHANGE_SUCCESS,
  SHOW_MESSAGE,
  HIDE_MESSAGE
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  data: null
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case DELETE_ORDER_SUCCESS: {
      return {
        ...state,
        loader: false,
        //data: action.payload
      }
    }
    case DELETE_ORDER: {
      return {
        ...state,
        loader: true,
        //data: action.payload
      }
    }
    case ORDER_INVOICE_CHANGE_SUCCESS: {
      return {
        ...state,
        loader: false,
        //data: action.payload
      }
    }
    case ORDER_INVOICE_CHANGE: {
      return {
        ...state,
        loader: true,
        //data: action.payload
      }
    }
    case ORDER_STATUS_CHANGE_SUCCESS: {
      return {
        ...state,
        loader: false,
        //data: action.payload
      }
    }
    case ORDER_STATUS_CHANGE: {
      return {
        ...state,
        loader: true,
        //data: action.payload
      }
    }
    case ORDER_FIELD_CHANGE_SUCCESS: {
      return {
        ...state,
        loader: false,
        //data: action.payload
      }
    }
    case ORDER_FIELD_CHANGE: {
      return {
        ...state,
        loader: true,
        //data: action.payload
      }
    }
    case ORDER_ADDITIONAL_PAYMENT_CHANGE_SUCCESS: {
      return {
        ...state,
        loader: false,
        //data: action.payload
      }
    }
    case ORDER_ADDITIONAL_PAYMENT_CHANGE: {
      return {
        ...state,
        loader: true,
        //data: action.payload
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    default:
      return state;
  }
}
