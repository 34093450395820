import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Company from "./Company";
import Products from "./Products";
import Orders from "./Orders";
import Variables from './Variables';
import Contact from "./Contact";
import Common from "./Common";


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  company: Company,
  products: Products,
  orders: Orders,
  variables: Variables,
  
  firebase: firebaseReducer,
  firestore: firestoreReducer 
});

export default createRootReducer
