import {
  VARIABLES_CHANGE_SUCCESS,
  VARIABLES_CHANGE,
  SHOW_MESSAGE,
  HIDE_MESSAGE
} from "constants/ActionTypes";

export const setVariables = (data) => {
  return {
    type: VARIABLES_CHANGE,
    payload: data
  };
};
export const setVariablesSuccess = (data) => {
  return {
    type: VARIABLES_CHANGE_SUCCESS,
    payload: data
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


