import React from "react";
import { Layout } from "antd";

import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";

import Topbar from "../Topbar/index";
import { footerText } from "util/config";
import App from "routes/index";
import { useSelector } from "react-redux";


import { connect } from "react-redux";
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase';
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import Customizer from "../Customizer";

const { Content, Footer } = Layout;

const MainApp = () => {

  const { width, navStyle } = useSelector(({ settings }) => settings);
  const match = useRouteMatch();

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };

  const getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  };


  return (
    <Layout className="gx-app-layout">
      {getSidebar(navStyle, width)}
      <Layout>
        {getNavStyles(navStyle)}
        <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
          <App match={match} />
          <Footer>
            <div className="gx-layout-footer-content">
              {footerText}
            </div>
          </Footer>
        </Content>
        <Customizer />
      </Layout>
    </Layout>
  )
};

const mapStateToProps = (state) => {
  //const {width, navStyle} = state.settings;
  const { auth } = state;
  //console.log(state);
  const { isAdmin } = state.firestore.data;
  //const accountUser = mainUser?mainUser[0]:null;
  return {
    //width, 
    //navStyle, 
    auth,
    isAdmin
    //accountUser
  };
};
export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const { auth, isAdmin } = props;
    const adminType = isAdmin ? isAdmin.type : null;
    console.log("mainrebuild");
    //console.log(props);
    const uid = auth.authUser;
    return uid ? [
       {
         collection: 'Admin', 
         doc: uid, 
         storeAs: 'isAdmin' 
       },
       {
         collection: 'Variables', 
         doc: 'MarketSettings',
         storeAs: "marketSettings"
       },
      adminType
        ?
        {
          collection: 'Customer',
          storeAs: 'myCompanies_c'
        }
        : {
          collection: 'Customer',
          where: [
            ['access', 'array-contains', '']
          ],
          storeAs: 'myCompanies_c'
        },
      adminType
        ? {
          collection: 'RegistrationRequest',
          where: [
            ['realized', '==', false],
          ],
          storeAs: 'registrationRequest'
        }
        : {
          collection: 'RegistrationRequest',
          where: [
            ['owner', '==', uid],
            ['disabled', '==', false]
          ],
          storeAs: 'registrationRequest'
        },
      adminType
        ? {
          collection: 'Seller',
          storeAs: 'myCompanies'
        }
        : {
          collection: 'Seller',
          where: [
            ['access', 'array-contains', uid]
          ],
          storeAs: 'myCompanies'
        }
    ] : []
  })
)(MainApp);

