import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
import rootSaga from "../sagas/index";
import createRootReducer from '../reducers'

import fbConfig from '../../firebase/firebase';
import { firebaseReducer, getFirebase } from 'react-redux-firebase';
import { createFirestoreInstance, firestoreReducer, getFirestore, actionTypes } from 'redux-firestore';
const createBrowserHistory = require('history').createBrowserHistory;


export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  thunk.withExtraArgument({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    getFirebase,
    getFirestore
  }),
  sagaMiddleware,
  routeMiddleware
];

const rrfConfig = {
  //userProfile: 'accounts',
  //attachAuthIsReady: true,
  useFirestoreForProfile: true,
  onAuthStateChanged: (authData, firebase, dispatch) => {
    // Clear redux-firestore state if auth does not exist (i.e logout)
    if (!authData) {
      dispatch({ type: actionTypes.CLEAR_DATA })
    }
  }
}

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
    ),
  );
  sagaMiddleware.run(rootSaga);
  return store;
}

export { rrfConfig, fbConfig, createFirestoreInstance };
