import React, { Fragment, useState, useEffect } from "react";
import { Card, Table, Input, Space, Button, message, Badge, Tooltip, Typography, Alert, Affix, Select, Tag, Modal } from "antd";
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
//import Text from "antd/lib/typography/Text";
import {
  hideMessage,
  setOrderStatus,
  removeOrder
  //acceptRegistrationForm,
  //userTwitterSignIn
} from "appRedux/actions/Orders";
import { stringToHslColor, hslToHex } from '../../util/color';
import { useDispatch, useSelector } from "react-redux";
import AdditionalFields from "./additionalPaymentModal";
import GeneratePdf from './generatePdf';
import EditField from './editField';
import EditTruck from './editTruck';
import CommentWindow from './comments';

const { Text } = Typography;
const { Option } = Select;


function tagRender(props) {
  const { label, value, closable, onClose } = props;
  //console.log(hslToHex(stringToHslColor(value, 40, 80)));
  //const hsl = stringToHslColor(value);
  return (
    <Tag color={hslToHex(stringToHslColor(value, 70, 60))} closable={closable} onClose={onClose} style={{ marginRight: 3, marginBottom: 0 }}>
      {label}
    </Tag>
  );
}
//part this thing
//
const showMessageUi = ({ type, content }) => {
  message[type](content);
};
//const Context = React.createContext({ name: 'Default' });
const Simple = (props) => {
  const dispatch = useDispatch();
  const { orders, loading, isAdmin, myCompanies, myCompanies_c, products, marketSettings } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [messsageApi, contextHolderMessage] = message.useMessage();
  const { loader, alertMessage, showMessage } = useSelector(({ orders }) => orders);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState([]);
  //console.log(useSelector(({ company }) => company));

  useEffect(() => {
    //showMessageUi({ type: 'error', content: alertMessage });
    console.log('update');
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
    }
  });

  /* if (showMessage) {
     
     let $timer = setTimeout(() => {
       clearTimeout($timer)
       $timer = null
       messsageApi.open({
       type: 'error',
       content: alertMessage,
     });
     }, 0)
   }*/
  const newReq = orders && isAdmin && !loading ? orders.map((item) => {
    return {
      ...item,
      key: item.id
      /*reqAccept: props.requestAccept,
      reqDelete: props.requestDelete */
    }
  }).filter(item => {
    if (selectedCompanies.length === 0)
      return true
    return selectedCompanies.find(cmpID => cmpID.key === item.companyID)
  }) : []
  //console.log('----------------sjdsdsdsdsd------------------------');
  //console.log(newReq);
  const onSelectChange = selectedRowKeys => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: changableRowKeys => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: changableRowKeys => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          /*ref={node => {
            this.searchInput = node;
          }}*/
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      //console.log(record)
      switch (dataIndex) {
        case 'companyID': {
          //console.log('-----search--------');
          let result = '';
          if (record[dataIndex]) {
            const { company_name } = myCompanies_c.find(({ id }) => id === record[dataIndex])
            result = company_name.toString().toLowerCase().includes(value.toLowerCase())
          }
          return result
        };
        default: return record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : ''
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        //setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  const expandedRowRender = ({ orderItems, km_price_eur, key, stockConfig }) => {
    const columns = [
      {
        title: 'Seller',
        dataIndex: 'companyID',
        key: 'companyID',
        render: (companyID) => {
          const company = myCompanies.find(({ id }) => id === companyID);
          const cName = company ? company.company_name : '-';
          return cName;
        }
      },
      /*{
        title: 'Ordered trucks',
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a, b) => ('' + a.amount).localeCompare(b.amount),
        ...getColumnSearchProps('amount'),
        render: (amount, item) => {

          let stock = null;
          if (products) {
            const product = products.find(({ id }) => id === item.id)
            if (product && product.quantity) {
              stock = product.quantity
            }
          }
          return <Fragment>
            {!products ?
              <Tooltip placement="top" title='For edit this field go to "products" and return here!'>
                <Text>{amount}</Text><EditOutlined />
              </Tooltip> :
              <>
                <Text type="warning">{Math.floor(stock / stockConfig)} left</Text>
                <EditField disabled={!products ? true : false} keyId={item.id} orderId={key} isAdmin={isAdmin} fieldValue={amount} fieldName={'amount'} min={1} step={1} max={Math.floor(stock / stockConfig) + amount} />
              </>}
          </Fragment>
        },
      },*/
      products ? {
        title: 'Loaded pallets',
        dataIndex: 'additionalPCS',
        key: 'additionalPCS',
        render: (additionalPCS, item) => {
          //const {tripCount} = item;
          console.log('------------sdjdsjfsdfsdfdsfsdfdsf---------------------');
          //console.log(!manualDeliveryPrice);
          console.log(additionalPCS);
          let stock = null;
          if (products) {
            const product = products.find(({ id }) => id === item.id)
            if (product && product.quantity) {
              stock = product.quantity
            }
          }
          return <Fragment>
            <Text type="warning">{stock} left</Text>
            {/*<EditTruck keyId={item.id} orderId={key} isAdmin={isAdmin} fieldValue={additionalPCS} fieldName={'additionalPCS'} min={-(stockConfig + additionalPCS)} step={1} max={stock + additionalPCS} />*/}
            <EditTruck keyId={item.id} orderId={key} isAdmin={isAdmin} fieldValue={additionalPCS ? (stockConfig + additionalPCS) : stockConfig} fieldName={'additionalPCS'} stockConfig={stockConfig} additionalPCS={additionalPCS} stock={stock} />
          </Fragment>
        }
        //sorter: (a, b) => ('' + a.price_EXW_loadplace).localeCompare(b.price_EXW_loadplace),
        //...getColumnSearchProps('price_EXW_loadplace')
      } : {},
      {
        title: 'Quality',
        dataIndex: 'quality_index',
        key: 'quality_index',
        sorter: (a, b) => ('' + a.quality_index).localeCompare(b.quality_index),
       // ...getColumnSearchProps('quality'),
        render: (quality_index, item) => {
          return quality_index!==undefined?marketSettings.PaletteTypes[quality_index]:item.quality/*<EditField
            permitions={item.permitions}
            disabled={!quality ? true : false}
            keyId={item.id}
            min={0.01}
            step={0.01}
            productID={item.key}
            isAdmin={isAdmin}
            fieldValue={quality}
            fieldName={'quality'} />*/
        }
      },
     /* {
        title: 'Quality',
        dataIndex: 'quality',
        key: 'quality',
        sorter: (a, b) => ('' + a.quality).localeCompare(b.quality),
        ...getColumnSearchProps('quality')
      },*/
      {
        title: 'Price',
        dataIndex: 'price_EXW_loadplace',
        key: 'price_EXW_loadplace',
        render: (priceEXW, item) => {
          return <EditField keyId={item.id} orderId={key} isAdmin={isAdmin} fieldValue={priceEXW} fieldName={'price_EXW_loadplace'} min={0.01} step={0.01} />
        }
        //sorter: (a, b) => ('' + a.price_EXW_loadplace).localeCompare(b.price_EXW_loadplace),
        //...getColumnSearchProps('price_EXW_loadplace')
      },
      {
        title: 'Delivery price EUR',
        dataIndex: 'manualDeliveryPrice',
        key: 'manualDeliveryPrice',
        render: (manualDeliveryPrice, item) => {
          const { tripCount } = item;
          // console.log('------------sdjdsjfsdfsdfdsfsdfdsf---------------------');
          // console.log(manualDeliveryPrice, '-55555');
          //console.log(item);
          return manualDeliveryPrice !== undefined
            ? <EditField keyId={item.id} orderId={key} isAdmin={isAdmin} fieldValue={manualDeliveryPrice} fieldName={'manualDeliveryPrice'} min={0.00} step={0.01} />
            : <EditField keyId={item.id} orderId={key} isAdmin={isAdmin} fieldValue={(Math.round(((km_price_eur * tripCount) + Number.EPSILON) * 100) / 100).toFixed(2)} fieldName={'manualDeliveryPrice'} min={0.00} step={0.01} />
        }
        //sorter: (a, b) => ('' + a.price_EXW_loadplace).localeCompare(b.price_EXW_loadplace),
        //...getColumnSearchProps('price_EXW_loadplace')
      },
      {
        title: 'Trip count',
        dataIndex: 'tripCount',
        key: 'tripCount',
        render: (tripCount) => {
          return <Text>{tripCount.toFixed(2)} km</Text>
        }
        //sorter: (a, b) => ('' + a.price_EXW_loadplace).localeCompare(b.price_EXW_loadplace),
        //...getColumnSearchProps('price_EXW_loadplace')
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        sorter: (a, b) => ('' + a.country).localeCompare(b.country),
        ...getColumnSearchProps('country')
      },
      /* {
         title: 'City',
         dataIndex: 'city',
         key: 'city',
         sorter: (a, b) => ('' + a.city).localeCompare(b.city),
         ...getColumnSearchProps('city')
       },
       {
         title: 'Postalcode',
         dataIndex: 'postalcode',
         key: 'postalcode',
         sorter: (a, b) => ('' + a.postalcode).localeCompare(b.postalcode),
         ...getColumnSearchProps('postalcode')
       },*/
      {
        title: 'Adress string',
        dataIndex: 'address_string',
        key: 'address_string',
        sorter: (a, b) => ('' + a.address_string).localeCompare(b.address_string),
        ...getColumnSearchProps('address_string')
      },
      {
        title: 'Additional costs',
        dataIndex: 'additionalCosts',
        key: 'additionalCosts',
        render: (additionalCosts, item) => {
          const tmpArr = additionalCosts ? additionalCosts : [];
          return (<>
            <Text type="warning">{`Total: ${tmpArr.map(({ summ }) => summ).reduce((a, b) => a + b, 0).toFixed(2)}`}</Text>
            <AdditionalFields keyId={item.id} orderId={key} additionalCosts={tmpArr} />
          </>)
        }
      },
      {
        title: "Customer comment",
        dataIndex: "customerComment",
        key: 'customerComment',
        render: (customerComment, item) => {
          return (
                <CommentWindow item={item} keyId={item.id} orderId={key} />
          )
        }
      }
    ];

    return <Table columns={columns} dataSource={orderItems.map((item) => ({
      key: item.id,
      //km_price_eur: item.km_price_eur,
      amount: item.amount,
      customerComment: item.customerComment,
      sellerComment: item.sellerComment,
      ...item.productItem
    }))} pagination={false} />;
  };
  const columns = [
    {
      title: 'Ordered ID/truck',
      dataIndex: 'orderNumber',
      sorter: (a, b) => ('' + a.status).localeCompare(b.status),
      ...getColumnSearchProps('orderNumber'),
      key: 'orderNumber',
      render: (orderNumber, { orderIteration, orderTruck }) => {
        // console.log('-*-*-*-*-*-*-*-*-*');
        //console.log(orderNumber, orderIteration);
        const displayNumber = orderNumber ? `${orderIteration}-${orderNumber}${orderTruck ? `/${orderTruck}` : ''}` : 'not avaiable';
        return {
          props: {
            style: { background: '#eee' },
          },
          children: (
            <Text>{displayNumber}</Text>
          )
        };
      }
    },
    {
      title: 'Ordered by',
      dataIndex: 'companyID',
      sorter: (a, b) => ('' + a.status).localeCompare(b.status),
      ...getColumnSearchProps('companyID'),
      key: 'companyID',
      render: (companyID, object) => {
        const company = myCompanies_c.find(({ id }) => id === companyID);
        const cName = company ? company.company_name : '-';
        return {
          props: {
            style: { background: '#eee' },
          },
          children: (
            <Badge style={{ backgroundColor: 'orange' }} count={object.fullyLoaded} offset={[10, 0]}>
              <Text>{cName}</Text>
            </Badge>
          )
        };
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => ('' + a.status).localeCompare(b.status),
      //...getColumnSearchProps('status')
      render: (status, { id }) => {

        return {
          props: {
            style: { background: '#eee' },
          },
          children: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Select defaultValue={status} onChange={value => {
                dispatch(setOrderStatus({
                  newStatus: value,
                  id
                }))
              }} >
                <Option value="Created" disabled>
                  Searching for transport
                </Option>
                <Option value="Waiting for payment">Transport found</Option>
                <Option value="Ready for loading">Paid</Option>
                <Option value="On the way">On the way</Option>
                <Option value="Finished">Delivered</Option>
              </Select>
              {/* <Button>Save</Button>*/}
              {/*<GeneratePdf/>*/}
            </div>
          )
        };
      }
    },
    {
      title: 'Invoice',
      dataIndex: 'invoiceURL',
      key: 'invoiceURL',
      sorter: (a, b) => ('' + a.status).localeCompare(b.status),
      //...getColumnSearchProps('status')
      render: (invoiceURL, order) => {
        const addDays = (date, days) => {
          var result = new Date(date);
          result.setDate(result.getDate() + days);
          return result;
        }
        const { companyID, timestamp } = order;
        const company = myCompanies_c.find(({ id }) => id === companyID);
        const cName = company ? company.company_name : '-';
        const cMail = company ? company.email : '-';
        const cAddress = company ? `${company.city}, ${company.address_string}, ${company.country}, ${company.postalcode}` : '-';
        const created = timestamp.toDate();
        let due = addDays(created, 90);

        //due = due.setDate(due.getDate() + 90).toDate
        return {
          props: {
            style: { background: '#eee' },
          },
          children: (
            <GeneratePdf invoiceURL={invoiceURL} order={{
              company_name: cName,
              company_email: cMail,
              company_address: cAddress,
              created: created.toDateString(),
              due: due.toDateString(),
              ...order
            }} key={order.key} />
          )
        };
      }
    },
    {
      title: 'Stock Config',
      dataIndex: 'stockConfig',
      key: 'stockConfig',
      sorter: (a, b) => ('' + a.stockConfig).localeCompare(b.stockConfig),
      ...getColumnSearchProps('stockConfig'),
      render: stockConfig => {

        return {
          props: {
            style: { background: '#eee' },
          },
          children:
            <>
              {
                stockConfig === 756 ?
                  <Tag color={'volcano'}>
                    {stockConfig}
                  </Tag> : null
              }
              {
                stockConfig === 561 ?
                  <Tag color={'orange'}>
                    {stockConfig}
                  </Tag> : null
              }
            </>
        }
      }
    },
    /*{
      title: 'Total trucks',
      dataIndex: 'orderItems',
      key: 'orderItems',
      */
    /* sorter: (a, b) => ('' + a.stockConfig).localeCompare(b.stockConfig),
     ...getColumnSearchProps('stockConfig'),*/
    /*
   render: (orderItems, { stockConfig }) => {
     // console.log("orderItems")
     //console.log(orderItems)

     return {
       props: {
         style: { background: '#eee' },
       },
       children: (
         <Text>{orderItems.map(({ amount }) => amount).reduce((a, b) => a + b, 0)}</Text>
       )
     }
   }
 },*/
    /*{
      title: 'Total distance',
      dataIndex: 'orderItems',
      key: 'orderItems',
      /* sorter: (a, b) => ('' + a.stockConfig).localeCompare(b.stockConfig),
       ...getColumnSearchProps('stockConfig'),*//*
render: (orderItems, { km_price_eur }) => {
  //  console.log("orderItems")
  //   console.log(orderItems)

  return {
    props: {
      style: { background: '#eee' },
    },
    children: (
      <Text>{orderItems.map(({ productItem: { tripCount } }) => (tripCount)).reduce((a, b) => a + b, 0).toFixed(2)}</Text>
    )
  }
}
},*/
    {
      title: 'Commision %',
      dataIndex: 'commission',
      key: 'commission',
      /* sorter: (a, b) => ('' + a.stockConfig).localeCompare(b.stockConfig),
       ...getColumnSearchProps('stockConfig'),*/
      render: (commission, item) => {
        //  console.log("orderItems")
        //   console.log(orderItems)

        return {
          props: {
            style: { background: '#eee' },
          },
          children: (
            <EditField keyId={item.id + 'commission'} orderId={item.id} isAdmin={isAdmin} fieldValue={commission} fieldName={'commission'} min={0.0} step={0.01} max={100} />
          )
        }
      }
    },
    {
      title: 'EUR per 1Km',
      dataIndex: 'km_price_eur',
      key: 'km_price_eur',
      /* sorter: (a, b) => ('' + a.stockConfig).localeCompare(b.stockConfig),
       ...getColumnSearchProps('stockConfig'),*/
      render: (km_price_eur, item) => {
        //  console.log("orderItems")
        //   console.log(orderItems)

        return {
          props: {
            style: { background: '#eee' },
          },
          children: (
            //<EditField keyId={item.id+'km_price_eur'} orderId={item.id} isAdmin={isAdmin} fieldValue={km_price_eur} fieldName={'km_price_eur'} min={0.0} step={0.01} max={100} />
            <Text>{km_price_eur}</Text>
          )
        }
      }
    },
    {
      title: 'Total summ',
      dataIndex: 'orderItems',
      key: 'orderItems',
      /* sorter: (a, b) => ('' + a.stockConfig).localeCompare(b.stockConfig),
       ...getColumnSearchProps('stockConfig'),*/
      render: (orderItems, { stockConfig, km_price_eur, commission }) => {
        //    console.log("orderItems")
        //    console.log(orderItems)
        //--=Костыль начался надеюсь это временно
        const { additionalPCS } = orderItems[0].productItem;// формируем общую загрузку грузовика по первой позиции, если позиций больше то пизда
        const truckLoad = stockConfig + (additionalPCS ? additionalPCS : 0)//нужно перенести конфигурацию загрузки в позициюб но не ща, ща мне нужен психиатор
        //---КОстыль закончился

        // console.log('-----------')
        // console.log(truckLoad);
        return {
          props: {
            style: { background: '#eee' },
          },
          children: (
            <Text>
              {
                ((Math.round(((orderItems.map(({ productItem: { price_EXW_loadplace, tripCount, additionalCosts }, amount }) => {
                  /*const truckLoad = stockConfig+(additionalPCS?additionalPCS:0)*/
                  const pieceDelivery = (Math.round((((tripCount * km_price_eur) / truckLoad) + Number.EPSILON) * 100) / 100).toFixed(2)
                  const totalPrice = ((pieceDelivery * truckLoad) + (commission * truckLoad) + (price_EXW_loadplace * truckLoad) + (additionalCosts ? additionalCosts.map(({ summ }) => summ).reduce((a, b) => a + b, 0) : 0))
                  // const rewPriceDDP = ((pieceDelivery * truckLoad) + (commission * truckLoad) + (price_EXW_loadplace * truckLoad) + (additionalCosts ? additionalCosts.map(({ summ }) => summ).reduce((a, b) => a + b, 0) : 0)) / truckLoad
                  //const priceDDP = (Math.round((rewPriceDDP + Number.EPSILON) * 100) / 100).toFixed(2)
                  // return priceDDP * amount
                  return totalPrice
                }).reduce((a, b) => a + b, 0) /** truckLoad*/) + Number.EPSILON) * 100) / 100)).toFixed(2)
                /*((orderItems.map(({
                  productItem: {
                    price_EXW_loadplace
                  }, quantity }) =>
                  (price_EXW_loadplace + commission) * quantity)
                  .reduce((a, b) => a + b, 0) * stockConfig)
                  +
                  orderItems.map(({ productItem: { tripCount, manualDeliveryPrice, additionalCosts } }) =>
                    ((manualDeliveryPrice ? manualDeliveryPrice : (tripCount * km_price_eur)) + additionalCosts ? additionalCosts.map(({ summ }) => summ).reduce((a, b) => a + b, 0) : 0)
                  ).reduce((a, b) => a + b, 0)).toFixed(2)*/
              }
            </Text>
          )
        }
      }
    },
    {
      title: 'Created',
      dataIndex: 'timestamp',
      sorter: (a, b) => ('' + a.timestamp).localeCompare(b.timestamp),
      key: 'timestamp',
      /* sorter: (a, b) => ('' + a.stockConfig).localeCompare(b.stockConfig),
       ...getColumnSearchProps('stockConfig'),*/
      render: (timestamp) => {
        //    console.log("orderItems")
        //    console.log(timestamp)

        return {
          props: {
            style: { background: '#eee' },
          },
          children: (
            <Text>{timestamp.toDate().toDateString()} {timestamp.toDate().toLocaleTimeString('lv-LV')}</Text>
          )
        }
      }
    },
  ];
  //const reqName = selectedRowKeys.length > 1 ? 'requests' : 'request';

  return (
    <Fragment>
      {showMessage ?
        <Affix offsetTop={15} >
          <Alert message={alertMessage.message} type={alertMessage.type} showIcon />
        </Affix>
        : null}
      <Card title={"Orders"} style={{height: (window.innerHeight*80)/100}} loading={!myCompanies_c && !myCompanies}>

        <Space size='large' direction="vertical" style={{ width: "100%" }}>
          {isAdmin && myCompanies_c
            ? <Select
              mode="multiple"
              showArrow
              title='Company'
              size='default'
              tagRender={tagRender}
              onChange={(value, selected) => {
                setSelectedCompanies(selected);
                // console.log(selectedCompanies);
              }}
              defaultValue={[]/*['gold', 'cyan']*/}
              style={{ width: '100%' }}
              options={myCompanies_c.map(item => ({ key: item.id, value: item.company_name/*, data: item */ }))}
            />
            : null}
          <Table
            rowSelection={rowSelection}
            className="gx-table-responsive"
            expandable={{ expandedRowRender }}
            loading={loader || loading}
            columns={columns}
            scroll={{ y: 'calc(55vh - 4em)' }}
            //footer=
            dataSource={newReq.filter(({ deleted }) => !deleted)} />
          <Space>
            {/*<AddProduct myCompanies={myCompanies}/>*/}
            {
              selectedRowKeys.length > 0
                ?
                <Button type="danger" onClick={() => {

                  dispatch(removeOrder({
                    ids: selectedRowKeys
                  }))
                  setSelectedRowKeys([])
                }}>Delete</Button>
                :
                <Button type="danger" disabled>Delete</Button>
            }
          </Space>
        </Space>
        {/*
          selectedRowKeys.length > 0
            ?
            <Fragment>
              <Button type="primary" onClick={() => dispatch(acceptRegistrationForm({
                ids: selectedRowKeys
              }))}>Accept {reqName}</Button>
              <Button type='dashed' onClick={() => showMessageUi({ type: 'info', content: `Removing ${reqName} is under construction!` })}>Remove {reqName}</Button>
            </Fragment>
            :
            <Fragment>
              <Button type="primary" disabled>Accept {reqName}</Button>
              <Button type='dashed' disabled>Remove {reqName}</Button>
            </Fragment>
            */}

      </Card>
    </Fragment>
  );
};

export default Simple;