// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';

//Company
export const END_COMPANY_REGISTRATION="END_COMPANY_REGISTRATION";
export const END_COMPANY_REGISTRATION_SUCCESS="END_COMPANY_REGISTRATION_SUCCESS";
export const ACCEPT_REGISTRATION = 'ACCEPT_REGISTRATION';
export const ACCEPT_REGISTRATION_SUCCESS = 'ACCEPT_REGISTRATION_SUCCESS';
export const REJECT_REGISTRATION = "REJECT_REGISTRATION";
export const REJECT_REGISTRATION_SUCCESS = 'REJECT_REGISTRATION_SUCCESS';
export const REJECT_BAN_REGISTRATION = 'REJECT_BAN_REGISTRATION';
export const REJECT_BAN_REGISTRATION_SUCCESS = 'REJECT_BAN_REGISTRATION_SUCCESS';
export const COMPANY_FIELD_CHANGE = 'COMPANY_FIELD_CHANGE';
export const COMPANY_FIELD_CHANGE_SUCCESS = 'COMPANY_FIELD_CHANGE_SUCCESS';

//Product
/*
  EDIT_FULL_PRODUCT,
  EDIT_FULL_PRODUCT_SUCCESS,
*/
export const ADD_PRODUCT="ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS="ADD_PRODUCT_SUCCESS";
export const EDIT_PRODUCT="EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS="EDIT_PRODUCT_SUCCESS";
export const EDIT_FULL_PRODUCT="EDIT_FULL_PRODUCT";
export const EDIT_FULL_PRODUCT_SUCCESS="EDIT_FULL_PRODUCT_SUCCESS";
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';

//Order
export const ORDER_INVOICE_CHANGE = 'ORDER_INVOICE_CHANGE';
export const ORDER_INVOICE_CHANGE_SUCCESS = 'ORDER_INVOICE_CHANGE_SUCCESS';
export const ORDER_STATUS_CHANGE = 'ORDER_STATUS_CHANGE';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const ORDER_STATUS_CHANGE_SUCCESS = 'ORDER_STATUS_CHANGE_SUCCESS';
export const ORDER_FIELD_CHANGE = 'ORDER_FIELD_CHANGE';
export const ORDER_FIELD_CHANGE_SUCCESS = 'ORDER_FIELD_CHANGE_SUCCESS';
export const ORDER_ADDITIONAL_PAYMENT_CHANGE = 'ORDER_ADDITIONAL_PAYMENT_CHANGE';
export const ORDER_ADDITIONAL_PAYMENT_CHANGE_SUCCESS = 'ORDER_ADDITIONAL_PAYMENT_CHANGE_SUCCESS';

//VARIABLES
export const VARIABLES_CHANGE = 'VARIABLES_CHANGE';
export const VARIABLES_CHANGE_SUCCESS = 'VARIABLES_CHANGE_SUCCESS';
