import React from "react";
 
import Page from "./Page";
import Auxiliary from "util/Auxiliary";
import {Col, Row} from "antd";

import {connect} from "react-redux";
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase';

const SamplePage = (props) => {
  const { isAdmin, orders, loading } = props;
  return (
    <div>
      <h2 className="title gx-mb-4">{!loading&&orders&&!isAdmin?'Not permited':null}{/*<IntlMessages id="sidebar.samplePage"/>*/}</h2>
      
      <div className="gx-d-flex justify-content-center">
        {/*<h4>Under construction. Here dummy data only!</h4>*/}
      </div>
      <Auxiliary>
        <Row>
          <Col span={24}>
            <Page {...props}/>
          </Col>
        </Row>
      </Auxiliary>
    </div>
  );
};

const mapStateToProps = ({firebase: { auth }, firestore: { ordered, data, status: { requesting } }, firestore }) => {
  console.log(firestore);
  const { uid } = auth;
  const { isAdmin } = data;
  const { myCompanies, myCompanies_c, orders, products } = ordered;//marketSettings
  const { marketSettings } = data;//marketSettings

  //console.log("------sdsdf-ssdfsf-sd-fs-d-sdf-s-df-sdf--------");
 // console.log(ordered);
  const loading = uid && requesting.myCompanies && requesting.myCompanies_c && requesting.orders && isAdmin;
  return {
    auth,
    isAdmin,
    myCompanies,
    myCompanies_c,
    orders,
    products,
    loading,
    marketSettings
  }
} 

export default compose( 
  connect(mapStateToProps),
  firestoreConnect((props)=>{
    const {auth, isAdmin} = props;
    const adminType = isAdmin?isAdmin.type:null;
    const uid = auth?auth.uid:null;
    return adminType&&uid?[
      {
        collection: 'Order', 
        orderBy: ['timestamp', 'desc'],
       /* where: [
          ['disabled', '==', false],
        ],*/
        storeAs: 'orders'
      },
      {
        collection: 'Product', 
        where: [
          ['disabled', '==', false],
        ],
        storeAs: 'products'
      }
    ]:[]
  })
)(SamplePage);