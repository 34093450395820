import {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_FULL_PRODUCT,
  EDIT_FULL_PRODUCT_SUCCESS,
  SHOW_MESSAGE,
  HIDE_MESSAGE
} from "constants/ActionTypes";

//export const endRegistration = (data) => {
export const editProduct = (data) => {
  return {
    type: EDIT_PRODUCT,
    payload: data
  };
};
//export const endRegistrationSuccess = (data) => {
export const editProductSuccess = (data) => {
  return {
    type: EDIT_PRODUCT_SUCCESS,
    payload: data
  };
};
//export const endRegistration = (data) => {
export const editFullProduct = (data) => {
  return {
    type: EDIT_FULL_PRODUCT,
    payload: data
  };
};
//export const endRegistrationSuccess = (data) => {
export const editFullProductSuccess = (data) => {
  return {
    type: EDIT_FULL_PRODUCT_SUCCESS,
    payload: data
  };
};
//export const endRegistration = (data) => {
export const addProduct = (data) => {
  return {
    type: ADD_PRODUCT,
    payload: data
  };
};
//export const endRegistrationSuccess = (data) => {
export const addProductSuccess = (data) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload: data
  };
};

//export const removeRegistrationForm = (data) => {
export const removeProduct = (data) => {
  return {
    type: DELETE_PRODUCT,
    payload: data
  };
};
//export const removeRegistrationFormSuccess = (data) => {
export const removeProductSuccess = (data) => {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: data
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


