import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  functions
} from "../../firebase/firebase";
import {
  VARIABLES_CHANGE_SUCCESS,
  VARIABLES_CHANGE,
} from "constants/ActionTypes";
import { setVariablesSuccess, showMessage } from "../../appRedux/actions/Variables";

const acceptHttps = async ({ cloudAction, data }) =>
  await functions.httpsCallable(cloudAction)({
    data
  })
    .then(data => data)
    .catch(error => error);

/*
const sendUpdatedRequestInformation = async (email, password) =>
  await auth.createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);
*/
function* setVariablesAction({ payload }) {
  try {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    //yield put(showMessage({message: 'Testing', type: 'warning'}));
    const setVariablesResult = yield call(acceptHttps, { cloudAction: 'update_variables', data: payload });
    console.log(setVariablesResult);
    
    if (setVariablesResult.data.message === 'ok') {
      yield put(setVariablesSuccess('Success'));
    }
    else {
      yield put(showMessage({ message: setVariablesResult.data.message, type: 'error' }));
    }

  } catch (error) {
    yield put(showMessage(error));
  }
}
export function* setVariables() {
  yield takeEvery(VARIABLES_CHANGE, setVariablesAction);
}

export default function* rootSaga() {
  yield all([
    fork(setVariables),
  ]);
}
