import React, { useState } from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from "react-router-dom";
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import "assets/vendors/style";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { Spin, Space, Button } from 'antd';
import configureStore, { history, rrfConfig, fbConfig, createFirestoreInstance } from './appRedux/store';
import "./firebase/firebase";
import App from "./containers/App/index";
import Axios from "axios";

const store = configureStore(/* provide initial state if any */);

const rrfProps = {
  firebase: fbConfig,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

const PreviewPdf = ({id}) => {
  const [link, setLink] = useState(null);
  const [err, setErr] = useState(null);
  //const get_data = async () => {
    /*https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%2F1611438603843?alt=media&token=a24e1a94-ca22-4ff7-bcd5-8a4f0c16e6de */
    /*https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%2F1611438603843*/
    Axios.get(`https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}`).then(data=>{
      console.log('--------good----------');
      console.log(data);
      const generatedLink = `https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.data.downloadTokens}`;
      console.log(generatedLink);
    /*  Axios.get({
        url: `https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.data.downloadTokens}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        console.log(response);
      })*/
      setLink(generatedLink);
      //console.log(`https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.downloadTokens}`);
    }).catch(e=>{
      console.log('--------error-------------')
      console.log(e);
      setErr('Invoice dont exist');
    })
 // }
 // get_data();
  return link?
  <div style={{marginTop: 20}}>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
      <Button href={link} type="link">Download</Button>
    <Viewer fileUrl={`https://cors-anywhere.herokuapp.com/${link}`} />
  </Worker>
  </div>
  :err?<div>{err}</div>:<Spin size="large" />
}
const DownloadPdf = ({id}) => {
  const [link, setLink] = useState(null);
  const [err, setErr] = useState(null);
  //const get_data = async () => {
    /*https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%2F1611438603843?alt=media&token=a24e1a94-ca22-4ff7-bcd5-8a4f0c16e6de */
    /*https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%2F1611438603843*/
    Axios.get(`https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}`).then(data=>{
      console.log('--------good----------');
      console.log(data);
      const generatedLink = `https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.data.downloadTokens}`;
      console.log(generatedLink);
    /*  Axios.get({
        url: `https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.data.downloadTokens}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        console.log(response);
      })*/
      setLink(generatedLink);
      //console.log(`https://firebasestorage.googleapis.com/v0/b/xpall-218fb.appspot.com/o/invoices%${id}?alt=media&token=${data.downloadTokens}`);
    }).catch(e=>{
      console.log('--------error-------------')
      console.log(e);
      setErr('Invoice dont exist');
    })
 // }
 // get_data();
  return link?
  <div style={{marginTop: 20}}>
      <Button href={link} type="link">Download</Button>
  </div>
  :err?<div>{err}</div>:<Spin size="large" />
}

const NextApp = () =>
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/getInvoice/:id" component={({match: {params: {id}}}) => {
            return <PreviewPdf id={id}/>
          }} />
          <Route path="/downloadInvoice/:id" component={({match: {params: {id}}}) => {
            return <DownloadPdf id={id}/>
          }} />
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </ReactReduxFirebaseProvider>
  </Provider>;


export default NextApp;
