import React, { Fragment, useState, useEffect } from "react";
import { Card, Table, Input, Space, Button, message, Alert, Affix, Typography } from 'antd';
import { HighlightOutlined, SmileOutlined, SmileFilled } from '@ant-design/icons';

import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Text from "antd/lib/typography/Text";
import {
  hideMessage,
  //acceptRegistrationForm,
  //userTwitterSignIn
} from "appRedux/actions/Company";


import { useDispatch, useSelector } from "react-redux";
const showMessageUi = ({ type, content }) => {
  message[type](content);
};
const { Paragraph } = Typography;
//const Context = React.createContext({ name: 'Default' });
const Simple = (props) => {
  const dispatch = useDispatch();
  const { companyData, loading, isAdmin } = props;
  const { loader, alertMessage, showMessage } = useSelector(({ company }) => company);
  

  const [editableStr, setEditableStr] = useState('Info.');

  return (
    <Fragment>
      {/*showMessage ?
        <Affix offsetTop={15} >
          <Alert message={alertMessage.message} type={alertMessage.type} showIcon />
        </Affix>
        : null*/}
      <Card title={"Company info"} loading={!companyData}>
      <Paragraph editable={{ onChange: setEditableStr }}>{editableStr}</Paragraph>
      
      <Paragraph editable={{ onChange: setEditableStr }}>{editableStr}</Paragraph>
      <Paragraph editable={{ onChange: setEditableStr }}>{editableStr}</Paragraph>
      <Paragraph editable={{ onChange: setEditableStr }}>{editableStr}</Paragraph>
      <Paragraph editable={{ onChange: setEditableStr }}>{editableStr}</Paragraph>
      </Card>
    </Fragment>
  );
};

export default Simple;