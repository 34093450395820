import React, { Fragment, useState, useEffect } from "react";
import { Card, Table, Input, Space, Button, message, Alert, Affix, Select, Tag, Modal } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
//import EditField from './editField';
//import Text from "antd/lib/typography/Text";
import {
  hideMessage,
  removeProduct
  //acceptRegistrationForm,
  //userTwitterSignIn
} from "appRedux/actions/Products";
import { stringToHslColor, hslToHex } from '../../util/color';
import { useDispatch, useSelector } from "react-redux";
import AddProduct from "./AddProduct";


function tagRender(props) {
  const { label, value, closable, onClose } = props;
  //console.log(hslToHex(stringToHslColor(value, 40, 80)));
  //const hsl = stringToHslColor(value);
  return (
    <Tag color={hslToHex(stringToHslColor(value, 70, 60))} closable={closable} onClose={onClose} style={{ marginRight: 3, marginBottom: 0 }}>
      {label}
    </Tag>
  );
}
//part this thing
//
const showMessageUi = ({ type, content }) => {
  message[type](content);
};
//const Context = React.createContext({ name: 'Default' });
const Simple = (props) => {
  const dispatch = useDispatch();
  const { products, loading, isAdmin, myCompanies, marketSettings } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [messsageApi, contextHolderMessage] = message.useMessage();
  const { loader, alertMessage, showMessage } = useSelector(({ products }) => products);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState([]);
  //console.log(useSelector(({ company }) => company));

  useEffect(() => {
    //showMessageUi({ type: 'error', content: alertMessage });
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
    }
  });
/*
   if (showMessage) {
     
     let $timer = setTimeout(() => {
       clearTimeout($timer)
       $timer = null
       messsageApi.open({
       type: 'error',
       content: alertMessage,
     });
     }, 0)
   }*/
  const newReq = products && isAdmin && !loading ? products.map((item) => {
    return {
      ...item,
      key: item.id
      /*reqAccept: props.requestAccept,
      reqDelete: props.requestDelete */
    }
  }).filter(item => {
    if (selectedCompanies.length===0)
      return true
    return selectedCompanies.find(cmpID => cmpID.key === item.companyID)
  }) : []
  const onSelectChange = selectedRowKeys => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: changableRowKeys => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: changableRowKeys => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          /*ref={node => {
            this.searchInput = node;
          }}*/
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        //setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  const columns = [
    /*{
      title: 'Product type',
      dataIndex: 'product_type',
      key: 'product_type',
      sorter: (a, b) => ('' + a.product_type).localeCompare(b.product_type),
      ...getColumnSearchProps('product_type')
    },*/
    {
      title: 'Company',
      dataIndex: 'companyID',
      key: 'companyID',
      render: (companyID) => {
        const company = myCompanies.find(({id})=>id===companyID);
        const cName = company?company.company_name:'-';
        return cName;
      }
      //sorter: (a, b) => ('' + a.quality).localeCompare(b.quality),
      //...getColumnSearchProps('quality')
    },
    {
      title: 'Quality',
      dataIndex: 'quality_index',
      key: 'quality_index',
      sorter: (a, b) => ('' + a.quality_index).localeCompare(b.quality_index),
     // ...getColumnSearchProps('quality'),
      render: (quality_index, item) => {
        return quality_index!==undefined?marketSettings.PaletteTypes[quality_index]:item.quality/*<EditField
          permitions={item.permitions}
          disabled={!quality ? true : false}
          keyId={item.id}
          min={0.01}
          step={0.01}
          productID={item.key}
          isAdmin={isAdmin}
          fieldValue={quality}
          fieldName={'quality'} />*/
      }
    },
    {
      title: 'Price',
      dataIndex: 'price_EXW_loadplace',
      key: 'price_EXW_loadplace',
      //sorter: (a, b) => ('' + a.price_EXW_loadplace).localeCompare(b.price_EXW_loadplace),
      //...getColumnSearchProps('price_EXW_loadplace'),
      render: (price_EXW_loadplace, item) => {
        return price_EXW_loadplace/*<EditField
          permitions={item.permitions}
          disabled={!price_EXW_loadplace ? true : false}
          keyId={item.id}
          min={0.01}
          step={0.01}
          productID={item.key}
          isAdmin={isAdmin}
          fieldValue={price_EXW_loadplace}
          fieldName={'price_EXW_loadplace'} />*/
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      //sorter: (a, b) => ('' + a.price_EXW_loadplace).localeCompare(b.price_EXW_loadplace),
      //...getColumnSearchProps('price_EXW_loadplace'),
      render: (quantity, item) => {
        return quantity/*<EditField
          permitions={item.permitions}
          disabled={!quantity ? true : false}
          keyId={item.id}
          min={1}
          step={1}
          productID={item.key}
          isAdmin={isAdmin}
          fieldValue={quantity}
          fieldName={'quantity'} />*/
      }
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      sorter: (a, b) => ('' + a.country).localeCompare(b.country),
      ...getColumnSearchProps('country')
    },
   /* {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      sorter: (a, b) => ('' + a.city).localeCompare(b.city),
      ...getColumnSearchProps('city')
    },
    {
      title: 'Postalcode',
      dataIndex: 'postalcode',
      key: 'postalcode',
      sorter: (a, b) => ('' + a.postalcode).localeCompare(b.postalcode),
      ...getColumnSearchProps('postalcode')
    },*/
    {
      title: 'Adress string',
      dataIndex: 'address_string',
      key: 'address_string',
      sorter: (a, b) => ('' + a.address_string).localeCompare(b.address_string),
      ...getColumnSearchProps('address_string'),
      render: (address_string, item) => {
        return address_string/*<EditField
          permitions={item.permitions}
          disabled={!address_string ? true : false}
          keyId={item.id}
          min={0.01}
          step={0.01}
          productID={item.key}
          isAdmin={isAdmin}
          fieldValue={address_string}
          fieldName={'address_string'} />*/
      }
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (id, item) => {
        return <AddProduct myCompanies={myCompanies} item={item} id={id} marketSettings={marketSettings}/>
      }
    },
  ];
  //const reqName = selectedRowKeys.length > 1 ? 'requests' : 'request';

  return (
    <Fragment>
      {showMessage ?
        <Affix offsetTop={15} >
          <Alert message={alertMessage.message} type={alertMessage.type} showIcon />
        </Affix>
        : null}
      <Card title={"Store"} loading={!myCompanies}>

        <Space size='large' direction="vertical" style={{ width: "100%" }}>
          {isAdmin && myCompanies
            ? <Select
              mode="multiple"
              showArrow
              title='Company'
              size='default'
              tagRender={tagRender}
              onChange={(value, selected) => {
                setSelectedCompanies(selected);
                // console.log(selectedCompanies);
              }}
              defaultValue={[]/*['gold', 'cyan']*/}
              style={{ width: '100%' }}
              options={myCompanies.map(item => ({ key: item.id, value: item.company_name/*, data: item */ }))}
            />
            : null}
          <Table
            rowSelection={rowSelection} 
            className="gx-table-responsive"
            loading={loader || loading}
            columns={columns}
            dataSource={newReq.filter(({deleted})=>!deleted)} />
          <Space>
            <AddProduct myCompanies={myCompanies}  marketSettings={marketSettings}/>
            {
              selectedRowKeys.length > 0
                ?
                <Button type="danger" onClick={() => {
                  
                  dispatch(removeProduct({
                  ids: selectedRowKeys
                  /*toDelete: selectedRowKeys.map(id=>({
                      id,
                      gallery: newReq.find(item=>item.id===id).gallery
                  }))*/
                  }))
                  setSelectedRowKeys([])
                }}>Delete</Button>
                :
                <Button type="danger" disabled>Delete</Button>
            }
          </Space>
        </Space>
        {/*
          selectedRowKeys.length > 0
            ?
            <Fragment>
              <Button type="primary" onClick={() => dispatch(acceptRegistrationForm({
                ids: selectedRowKeys
              }))}>Accept {reqName}</Button>
              <Button type='dashed' onClick={() => showMessageUi({ type: 'info', content: `Removing ${reqName} is under construction!` })}>Remove {reqName}</Button>
            </Fragment>
            :
            <Fragment>
              <Button type="primary" disabled>Accept {reqName}</Button>
              <Button type='dashed' disabled>Remove {reqName}</Button>
            </Fragment>
            */}

      </Card>
    </Fragment>
  );
};

export default Simple;