import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  functions
} from "../../firebase/firebase";
import {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_FULL_PRODUCT,
  EDIT_FULL_PRODUCT_SUCCESS,
} from "constants/ActionTypes";
import { addProductSuccess, editProductSuccess, editFullProductSuccess, removeProductSuccess, showMessage } from "../../appRedux/actions/Products";

const acceptHttps = async ({ cloudAction, data }) =>
  await functions.httpsCallable(cloudAction)({
    data
  })
    .then(data => data)
    .catch(error => error);

/*
const sendUpdatedRequestInformation = async (email, password) =>
  await auth.createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);
*/
function* addProductAction({ payload }) {
  try {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    //yield put(showMessage({message: 'Testing', type: 'warning'}));
    const addProductResult = yield call(acceptHttps, { cloudAction: 'add_product', data: payload });
    console.log(addProductResult);
    
    if (addProductResult.data.message === 'ok') {
      yield put(addProductSuccess('Success'));
    }
    else {
      yield put(showMessage({ message: addProductResult.data.message, type: 'error' }));
    }

  } catch (error) {
    yield put(showMessage(error));
  }
}
export function* addProduct() {
  yield takeEvery(ADD_PRODUCT, addProductAction);
}

function* editPoductAction({ payload }) {
  try {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    //yield put(showMessage({message: 'Testing', type: 'warning'}));
    const editProductResult = yield call(acceptHttps, { cloudAction: 'update_product_field_admin', data: payload });
    console.log(editProductResult);
    
    if (editProductResult.data.message === 'ok') {
      yield put(addProductSuccess('Success'));
    }
    else {
      yield put(showMessage({ message: editProductResult.data.message, type: 'error' }));
    }

  } catch (error) {
    yield put(showMessage(error));
  }
}
export function* editProduct() {
  yield takeEvery(EDIT_PRODUCT, editPoductAction);
}
function* editFullPoductAction({ payload }) {
  try {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    //yield put(showMessage({message: 'Testing', type: 'warning'}));
    const editProductResult = yield call(acceptHttps, { cloudAction: 'edit_product', data: payload });
    console.log(editProductResult);
    
    if (editProductResult.data.message === 'ok') {
      yield put(editFullProductSuccess('Success'));
    }
    else {
      yield put(showMessage({ message: editProductResult.data.message, type: 'error' }));
    }

  } catch (error) {
    console.log('**************************************************');
    yield put(showMessage(error));
  }
}
export function* editFullProduct() {
  yield takeEvery(EDIT_FULL_PRODUCT, editFullPoductAction);
}

function* deleteProductAction({ payload }) {
  //console.log(payload);
  
  try {
    const updateResult = yield call(acceptHttps, { cloudAction: 'delete_product', data: payload });
    //console.log(updateResult);
    if( updateResult instanceof Error ){
      yield put(showMessage({ message: 'Connection error', type: 'error' }));
    }
    else{
      const { data: { message } } = updateResult;
      if (message === 'ok') {
        yield put(removeProductSuccess('Success'));
      }
      else {
        yield put(showMessage({ message: message ? message : 'Connection error', type: 'error' }));
      }
    }
  } catch (error) {
    //console.log('!!!!!!!!!!!!!!!!!!!!**!!!!!!!!!')
    yield put(showMessage(error));
  }
}
export function* deleteProduct() {
  yield takeEvery(DELETE_PRODUCT, deleteProductAction);
}


export default function* rootSaga() {
  yield all([
    fork(addProduct),
    fork(deleteProduct),
    fork(editProduct),
    fork(editFullProduct)
  ]);
}
