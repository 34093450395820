import React from "react";
 
import Page from "./Page";
import Auxiliary from "util/Auxiliary";
import {Col, Row} from "antd";

import {connect} from "react-redux";
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase';

const SamplePage = (props) => {
  const { isAdmin, marketSettings, loading } = props;
  return (
    <div>
      <h2 className="title gx-mb-4">{!loading&&marketSettings&&!isAdmin?'Not permited':null}{/*<IntlMessages id="sidebar.samplePage"/>*/}</h2>
      
      <div className="gx-d-flex justify-content-center">
        {/*<h4>Under construction. Here dummy data only!</h4>*/}
      </div>
      <Auxiliary>
        <Row>
          <Col span={24}>
            <Page {...props}/>
          </Col>
        </Row>
      </Auxiliary>
    </div>
  );
};

const mapStateToProps = ({firebase: { auth }, firestore: { ordered, data, status: { requesting } }, firestore }) => {
  console.log(firestore);
  const { uid } = auth;
  const { isAdmin } = data;
  const { marketSettings } = ordered;
  const loading = uid && requesting.marketSettings && isAdmin;
  return {
    auth,
    isAdmin,
    marketSettings,
    loading
  }
} 

export default compose( 
  connect(mapStateToProps),
  /*firestoreConnect((props)=>{
    const {auth, isAdmin} = props;
    const adminType = isAdmin?isAdmin.type:null;
    const uid = auth?auth.uid:null;
    return adminType&&uid?[
      {
        collection: 'Variables', 
        doc: 'MarketSettings',
        storeAs: "marketSettings"
      }
    ]:[]
  })*/
)(SamplePage);