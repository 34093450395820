import React, { useState, useEffect, Fragment } from "react";
import { Card, Table, Modal, Form, Input, Select, Tabs, Button, Space, Affix, Alert, Spin } from "antd";
import { Link } from "react-router-dom";
import { languages, countries } from "countries-list";
import PhoneCodeData from "../../assets/misc/PhoneCodes";
import Icon from "@ant-design/icons";

import {
    hideMessage,
    endRegistration,
    //userTwitterSignIn
} from "appRedux/actions/Company";

import { useDispatch, useSelector } from "react-redux";
//import {bindActionCreators, compose} from "redux";
//import {firestoreConnect} from "react-redux-firebase";
//import * as RequestsActions from "../../appRedux/actions/Requests";
//const confirm = Modal.confirm;
import {
    showAuthLoader,
} from "appRedux/actions/Auth";
import Text from "antd/lib/typography/Text";
const { Option } = Select;
const FormItem = Form.Item;
const InputGroup = Input.Group;
const { confirm } = Modal;
const { TabPane } = Tabs;
const ConfirmModal = (uid, msg, onSuccess) => {
    confirm({
        title: 'Are you shure?',
        content: msg,
        onOk: () => {
            onSuccess(uid);
        },
        onCancel() {
            console.log('Cancel');
        },
    });
}


const Simple = (props) => {
    const dispatch = useDispatch();
    const { loader, alertMessage, showMessage } = useSelector(({ company }) => company);
    console.log("+++++++++++++++++++++++++++++");
    console.log(useSelector(({ company }) => company));
    const { registrationRequest, loading, isAdmin } = props;
    //console.log(props);
    const [changed, setChanged] = useState(false);
    const [form] = Form.useForm();
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 3000);
        }
    });
    const allFormsComplete = () => {
        //console.log("++++++++");
        //console.log(changed);
        const checkArray = Object.values(form.getFieldsValue([
            "company_code",
            "VAT_n",
            "country",
            "city",
            "address_string",
            "postalcode",
            "company_URL",
            "language",
            ['phone', 'code'],
            ['phone', 'number'],
            "city_actual",
            "address_string_actual",
            'postalcode_actual',
            "cp_first_name",
            "cp_last_name",
            ['cp_phone', 'code'],
            ['cp_phone', 'number'],
            "cp_email"
        ])).filter(item => item)
        //console.log(checkArray.length!==16);
        return checkArray.length === 16;
    }
    const cData = registrationRequest && !isAdmin ? registrationRequest[0] : null;
    const loadingMain = loading || !cData;
    const myCompany = registrationRequest && !isAdmin && !loadingMain ? {
        ...cData
    } : null
    const onFinish = values => {
        confirm({
            title: 'Apply data?.',
            //content: 'We will send e-mail for verification.',
            onOk: () => {
                console.log('OK');
                //dispatch(showAuthLoader());
                //console.log(values);
                dispatch(endRegistration({
                    ...values,
                    id: myCompany.id
                }));
                //this.props.showAuthLoader();
                //this.props.userSignUp(values);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    return (
        <Fragment>
            {showMessage ?
                <Affix offsetTop={15}>
                    <Alert message={alertMessage.message} type={alertMessage.type} showIcon />
                </Affix>
                : null}
            <Spin spinning={loader} tip='Apply data...'>

                <Card title={loadingMain ? '...' : "Company registration"} loading={loadingMain}>
                    {
                        !myCompany ? <Text>...</Text> :
                            myCompany.complete
                                ?
                                myCompany.realized
                                    ?
                                    <Link to='/my_company'>
                                        <Button type="link">Company was registred! More info is here.</Button>
                                    </Link>
                                    : <Text>Thank you for registration. Please wait for apply.</Text>
                                :
                                <Form
                                    initialValues={{ remember: true }}
                                    name="basic"
                                    form={form}
                                    onFinish={onFinish}

                                    onFinishFailed={onFinishFailed}
                                    className="gx-signin-form gx-form-row0">
                                    <Tabs defaultActiveKey='1'>
                                        <TabPane tab="Company info" key="1">
                                            <Space size='middle' direction="vertical" style={{ marginTop: 0, marginBottom: 15 }}>
                                                <Text>Company type: Seller</Text>
                                                <Text>Company name: {myCompany ? myCompany.company_name : '...'}</Text>
                                            </Space>
                                            {/*<FormItem rules={[{ required: true, message: 'Please input company name!' }]} name="company_name">
                            <Input placeholder="Company name" />
                        </FormItem>*/}
                                            <FormItem rules={[{ required: true, message: 'Please input company code!' }]} name="company_code">
                                                <Input placeholder="Company code" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                            <FormItem rules={[{ required: true, message: 'Please input valid VAT!' }]} name="VAT_n">
                                                <Input placeholder="Valid VAT" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                            <FormItem rules={[{ required: true, message: 'Please select country (legal)!' }]} name="country">
                                                <Select
                                                    showSearch
                                                    placeholder="Select country (legal)"
                                                    onChange={() => { setChanged(!changed) }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {Object.values(countries).map(({ native, name }) =>
                                                        <Option key={name} value={name}>{`${name} / ${native}`}</Option>
                                                    )}
                                                </Select>
                                            </FormItem>
                                            <FormItem rules={[{ required: true, message: 'Please select city (legal)!' }]} name="city">
                                                <Input placeholder="City (legal)" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                            <FormItem rules={[{ required: true, message: 'Please input address (legal)!' }]} name="address_string">
                                                <Input placeholder="Address (legal)" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                            <FormItem rules={[{ required: true, message: 'Please input postcode (legal)!' }]} name="postalcode">
                                                <Input placeholder="Postcode (legal)" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                        </TabPane>
                                        <TabPane tab="Contacts" key="2">
                                            {/*<FormItem name="email" rules={[{
                            required: true, type: 'email', message: 'The input is not valid E-mail!',
                        }]}>
                            <Input placeholder="Email" />
                        </FormItem>*/}
                                            <Space size='middle' direction="vertical" style={{ marginTop: 0, marginBottom: 15 }}>
                                                <Text>Email: {myCompany ? myCompany.email : '...'}</Text>
                                            </Space>
                                            <FormItem rules={[{ required: true, message: 'Please input WEB site!' }]} name="company_URL">
                                                <Input placeholder="Website" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                            <FormItem rules={[{ required: true, message: 'Please select language!' }]} name="language">
                                                <Select
                                                    showSearch
                                                    onChange={() => { setChanged(!changed) }}
                                                    placeholder="Select language"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {Object.values(languages).map(({ native, name }) =>
                                                        <Option key={name} value={name}>{`${name} / ${native}`}</Option>
                                                    )}
                                                </Select>
                                            </FormItem>
                                            <FormItem>
                                                <InputGroup compact>
                                                    <FormItem
                                                        name={['phone', 'code']}
                                                        noStyle
                                                        rules={[{ required: true, message: 'Code is required!' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            onChange={() => { setChanged(!changed) }}
                                                            style={{ width: '30%' }}
                                                            placeholder="Select code"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }>
                                                            {Object.values(PhoneCodeData).map(({ code, name }, num) =>
                                                                <Option key={code + ' ' + num} value={code}>{`${code} ${name}`}</Option>
                                                            )}
                                                        </Select>
                                                    </FormItem>

                                                    <FormItem
                                                        name={['phone', 'number']}
                                                        noStyle
                                                        rules={[{ required: true, message: 'Number is required' }]}
                                                    >
                                                        <Input style={{ width: '70%' }} placeholder="Number" onInput={() => { setChanged(!changed) }} />
                                                    </FormItem>

                                                </InputGroup>
                                            </FormItem>
                                            <FormItem rules={[{ required: true, message: 'Please select city (actual)!' }]} name="city_actual">
                                                <Input placeholder="City (actual)" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                            <FormItem rules={[{ required: true, message: 'Please input address (actual)!' }]} name="address_string_actual">
                                                <Input placeholder="Address (actual)" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                            <FormItem rules={[{ required: true, message: 'Please input postcode (actual)!' }]} name='postalcode_actual'>
                                                <Input placeholder="Postcode (actual)" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                        </TabPane>
                                        <TabPane tab="Сontact person" key="3">
                                            <FormItem rules={[{ required: true, message: 'Please input your first name!' }]} name="cp_first_name">
                                                <Input placeholder="FirstName" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                            <FormItem rules={[{ required: true, message: 'Please input your last name!' }]} name="cp_last_name">
                                                <Input placeholder="LastName" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                            <FormItem>
                                                <InputGroup compact>
                                                    <FormItem
                                                        name={['cp_phone', 'code']}
                                                        noStyle
                                                        rules={[{ required: true, message: 'Code is required!' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            onChange={() => { setChanged(!changed) }}
                                                            style={{ width: '30%' }}
                                                            placeholder="Select code"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }>
                                                            {Object.values(PhoneCodeData).map(({ code, name }, num) =>
                                                                <Option key={code + ' ' + num} value={code}>{`${code} ${name}`}</Option>
                                                            )}
                                                        </Select>
                                                    </FormItem>

                                                    <FormItem
                                                        name={['cp_phone', 'number']}
                                                        noStyle
                                                        rules={[{ required: true, message: 'Number is required' }]}
                                                    >
                                                        <Input style={{ width: '70%' }} placeholder="Number" onInput={() => { setChanged(!changed) }} />
                                                    </FormItem>

                                                </InputGroup>
                                            </FormItem>
                                            <FormItem name="cp_email" rules={[{
                                                required: true, type: 'email', message: 'The input is not valid E-mail!',
                                            }]}>
                                                <Input placeholder="Email" onInput={() => { setChanged(!changed) }} />
                                            </FormItem>
                                        </TabPane>

                                    </Tabs>
                                    <FormItem>
                                        {allFormsComplete() ? <Button type="primary" className="gx-mb-0" htmlType="submit">
                                            Accept
                    </Button> : <Button type="primary" className="gx-mb-0" disabled>
                                                Accept
                    </Button>}
                                        <Button type="dashed" className="gx-mb-0" danger onClick={() => {
                                            form.resetFields();
                                        }}>
                                            Clear
                    </Button>
                                    </FormItem>
                                </Form>
                    }
                </Card>
            </Spin>
        </Fragment>
    );
};

export default Simple;