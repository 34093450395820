import React from "react";
import {Card, Divider, Table, Modal} from "antd";
import Icon from "@ant-design/icons";

//import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
//import {firestoreConnect} from "react-redux-firebase";
//import * as RequestsActions from "../../appRedux/actions/Requests";
const confirm = Modal.confirm;

const ConfirmModal = (uid, msg, onSuccess) => {
  confirm({
    title: 'Are you shure?',
    content: msg,
    onOk: () => {
      onSuccess(uid);
    },
    onCancel() {
      console.log('Cancel');
    },
  });
}

const columns = [
  {
    title: 'Company name',
    dataIndex: 'company_name',
    key: 'company_name',
    //render: text => <span className="gx-link">{text}</span>,
  },
  {
    title: 'VAT',
    dataIndex: 'VAT_n',
    key: 'VAT_n',
  },
  {
    title: 'Adsress',
    dataIndex: 'address_string',
    key: 'address_string',
  },
  {
    title: 'Website',
    dataIndex: 'company_URL',
    key: 'company_URL',
    //<Link href="#components-anchor-demo-static" title="Static demo" />
    render: text => <a target='_blank' href={text}>
             {text}
     </a>,
  },
  {
    title: 'Code',
    dataIndex: 'company_code',
    key: 'company_code',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Language',
    dataIndex: 'language',
    key: 'language',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
 /* {
    title: 'Email verified',
    dataIndex: 'emailVerified',
    key: 'emailVerified',
    render: (text) => {
      //console.log(text);
      return text?(<i style={{fontSize: 24,color: 'limegreen'}} className="icon icon-check-cricle"/>):(<i style={{fontSize: 24,color: 'red'}} className="icon icon-close-circle"/>)
    }
  },*/
  /*{
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <span>
      <span className="gx-link" onClick={()=>{ConfirmModal(record.key, `To create account for ${record.comName} on ${record.email}.`, (uid)=>{record.reqAccept(uid)})}}>Accept</span>
      <Divider type="vertical"/>
      <span className="gx-link" onClick={()=>{ConfirmModal(record.key, `To delete request from ${record.email}.`, (uid)=>{record.reqDelete(uid)})}}>Delete</span>
      <Divider type="vertical"/>
      <span className="gx-link ant-dropdown-link">
        More actions <Icon type="down"/>
      </span>
    </span>
    ),
  }*/
];

const Simple = (props) => {
  const {registrationRequest, loading, isAdmin} = props;
  console.log(props);
  const newReq = registrationRequest?registrationRequest.map((item)=>{
    return {
      ...item,
      /*reqAccept: props.requestAccept,
      reqDelete: props.requestDelete */
    }
  }):[]
  return (
    <Card title={isAdmin?"Incoming":"Pending"}>
      {<Table className="gx-table-responsive" loading={loading} columns={columns} dataSource={newReq}/>}
    </Card>
  );
};

const  mapStateToProps = ({/*auth*/firebase: { auth }, firestore: { ordered, data, status:{requesting} }, firestore }) => {
  console.log(firestore);
  const { uid } = auth;
  const { isAdmin } = data;
  const { registrationRequest } = ordered;
  //console.log(registrationRequest);
  const loading = requesting.registrationRequest && isAdmin;
  return{
    isAdmin,
    registrationRequest,
    loading
  }
}
/*
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      requestAccept: RequestsActions.requestAccept,
      requestDelete: RequestsActions.requestDelete
    }, dispatch)
}*/

export default connect(mapStateToProps/*, mapDispatchToProps*/)(Simple);