import React, { useEffect, useState } from 'react';
import { Button, Modal, Typography, Upload } from 'antd';
//import $ from 'jquery';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { pdf, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import firebase from 'firebase';
import { jsPDF } from "jspdf";
import { useDispatch, useSelector } from "react-redux";
//import Hb from 'handlebars';

import {
  hideMessage,
  setOrderInvoice
  //acceptRegistrationForm,
  //userTwitterSignIn
} from "appRedux/actions/Orders";
const { Link } = Typography;
export default ({ order, invoiceURL }) => {
  const proceedPdf = () => {
    const doc = new jsPDF();
    //doc.html($(invoiceTemplate()));
    console.log('------');
    //doc.save("Invoice.pdf");
  }

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [fileName, setFileName] = useState(null);
  const [onUploadStart, setOnUploadStart] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [onUploadError, setOnUploadError] = useState(null);
  const [onUploadSuccess, setOnUploadSuccess] = useState(false);
  const [fileURL, setFileURL] = useState(null);


  const uploadToFirebase = (blob) => {
    firebaseUpload({ file: blob })
  }

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const firebaseUpload = async (data) => {
    //const { firebase } = this.props;
    const { file } = data;
    const newFileName = `${new Date().getTime()}`;
    const ref = firebase
      .storage()
      .ref('invoices')
      .child(newFileName);

    const task = ref.put(file);
    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      async snapshot => {
        const progress = Math.floor(
          (100 * snapshot.bytesTransferred) / snapshot.totalBytes
        );
        //setFileUID(file.uid);
        setFileName(newFileName);
        setOnUploadStart(true);
        setUploadProgress(progress);
      },
      error => {
        // Handle error during the upload
        setOnUploadError(error);
        setOnUploadSuccess(false);
      },
      async () => {
        await task.snapshot.ref
          .getDownloadURL()
          .then(downloadURL => {
            setFileURL(downloadURL);

            dispatch(setOrderInvoice({
              invoiceURL: downloadURL,
              id: order.id
            }))
            //setFileUID(file.uid);
          });
        setOnUploadSuccess(true);
        setOnUploadStart(false);
        //setFileUID(null);
        setFileName(null);
      }
    );
  }
  const { km_price_eur, commission, orderNumber, orderIteration, orderTruck, orderItems, company_name, company_email, company_address, created, due, stockConfig } = order;
  console.log('orderItems');
  const orderItemsView = orderItems.map(({ amount, productItem: { quality, country, additionalPCS, price_EXW_loadplace, manualDeliveryPrice, tripCount, additionalCosts } }) => {
    //(Math.round((((tripCount * km_price_eur) / stockConfig) + Number.EPSILON) * 100) / 100).toFixed(2)
    const truckLoad = stockConfig + (additionalPCS ? additionalPCS : 0)
    const pieceDelivery = (Math.round((((manualDeliveryPrice ? manualDeliveryPrice : (tripCount * km_price_eur)) / truckLoad) + Number.EPSILON) * 100) / 100).toFixed(2)
    //const pricePerPcs = (Math.round((((price_EXW_loadplace + commission) + (((manualDeliveryPrice ? manualDeliveryPrice : tripCount * km_price_eur) + (additionalCosts ? additionalCosts.map(({ summ }) => summ).reduce((a, b) => a + b, 0) : 0)) / stockConfig)) + Number.EPSILON) * 100) / 100).toFixed(2)
    //const totalPalletsPrice = (Math.round(((((amount * stockConfig) /*+ (additionalPCS ? additionalPCS : 0)*/) * pricePerPcs) + Number.EPSILON) * 100) / 100).toFixed(2);
    const totalPalletsPrice = ((pieceDelivery * truckLoad) + (commission * truckLoad) + (price_EXW_loadplace * truckLoad) + (additionalCosts ? additionalCosts.map(({ summ }) => summ).reduce((a, b) => a + b, 0) : 0)).toFixed(2);
    const pricePerPcs = (Math.round(((totalPalletsPrice / truckLoad) + Number.EPSILON) * 100) / 100).toFixed(2);
    return <View style={styles.tableItem}>
      <Text style={{ ...styles.tableHeaderText, fontSize: 12 }}>
        {`${country}-"${quality}"; Config: ${stockConfig === 756 ? 'One in one' : 'One on one'}; ${(amount * stockConfig) + (additionalPCS ? additionalPCS : 0)} pallets; ${pricePerPcs} €/pcs DDP`}
      </Text>
      <Text style={styles.tableHeaderText}>
        {totalPalletsPrice}
      </Text>
    </View>
  })
  const totalCost = (Math.round((orderItems.map(({ amount, productItem: { country, additionalPCS, price_EXW_loadplace, manualDeliveryPrice, tripCount, additionalCosts } }) => {
    const truckLoad = stockConfig + (additionalPCS ? additionalPCS : 0)
    const pieceDelivery = (Math.round((((manualDeliveryPrice ? manualDeliveryPrice : (tripCount * km_price_eur)) / truckLoad) + Number.EPSILON) * 100) / 100).toFixed(2)
    //const pricePerPcs = ((price_EXW_loadplace + commission) + (((manualDeliveryPrice ? manualDeliveryPrice : tripCount * km_price_eur) + (additionalCosts ? additionalCosts.map(({ summ }) => summ).reduce((a, b) => a + b, 0) : 0)) / stockConfig)).toFixed(2);
    const totalPalletsPrice = (pieceDelivery * truckLoad) + (commission * truckLoad) + (price_EXW_loadplace * truckLoad) + (additionalCosts ? additionalCosts.map(({ summ }) => summ).reduce((a, b) => a + b, 0) : 0);//(amount * truckLoad) * pricePerPcs;
    return totalPalletsPrice;
  }).reduce((a, b) => a + b, 0) + Number.EPSILON) * 100) / 100).toFixed(2);
  const displayNumber = orderNumber ? `${orderIteration}-${orderNumber}${orderTruck ? `/${orderTruck}` : ''}` : 'not avaiable';
  return (
    <>
      <Modal
        visible={visible}
        width='80%'
        title="Title"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          invoiceURL ? <Button type="link" target="_blank" href={invoiceURL}>Link Button</Button> : null,
          <Upload
            accept=".pdf"
            showUploadList={false}
            beforeUpload={file => {
              /*console.log(file);
              const reader = new FileReader();

              reader.onload = e => {
                console.log(e.target.result);
              };*/

              uploadToFirebase(file);
              // reader.readAsText(file);

              // Prevent upload
              return false;
            }}
          >
            <Button>
              Upload from computer
          </Button>
          </Upload>,
          <PDFDownloadLink document={<PdfDoc totalCost={totalCost} km_price_eur={km_price_eur} commission={commission} stockConfig={stockConfig} displayNumber={displayNumber} orderItemsView={orderItemsView} company_name={company_name} company_email={company_email} company_address={company_address} created={created} due={due} />} fileName="somename.pdf">
            {({ blob, url, loading, error }) => (
              <Button onClick={() => {
                console.log('---------------------');
                console.log(blob);
                uploadToFirebase(blob);
              }} loading={loading}>
                {'Publish & download'}
              </Button>)}
          </PDFDownloadLink>,
          <Button key="back" onClick={handleCancel}>
            Return
            </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            Submit
            </Button>,
        ]}
      >
        <PDFViewer width="100%" height={800}>
          <PdfDoc totalCost={totalCost} km_price_eur={km_price_eur} commission={commission} stockConfig={stockConfig} displayNumber={displayNumber} orderItemsView={orderItemsView} company_name={company_name} company_email={company_email} company_address={company_address} created={created} due={due} />
        </PDFViewer>
      </Modal>
      <Button onClick={showModal} type="primary">Details</Button>
    </>
  );
};


const PdfDoc = ({ totalCost, orderItemsView, displayNumber, company_name, company_email, company_address, created, due }) => (
  <Document>
    <Page style={styles.body}>
      <View style={styles.flexContainer}>
        <View style={styles.flexInnerLeftContainer}>
          {/*<Image
            style={styles.image}//https://uc31dad49f1cc5035a0abe12368a.dl.dropboxusercontent.com/cd/0/get/BIPlM4P-bLUWI1I2_jgZvJPpzoPQpxtlDiGAyJVYycDjrYr6BpBNIA24CzthFroLxIQU6JtYjRZgulLgyF47-7mTgEfZcouTguCweIcC9QNeHS6Hd8S42VgzyM4XVXUKgsk/file?_download_id=6559755838468113746193706899418051295529019970592455559433358363&_notify_domain=www.dropbox.com&dl=1
            src={ {uri: 'https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832__340.jpg', method: 'GET', headers: {}, body: ''} }//"/XPALLbwlogo.png"
          />*/}
          {<View style={styles.flexContainer}>
            <Text style={{ fontWeight: 'bold', fontSize: 46, color:'#FFC000' }}>
              X
            </Text>
            <Text style={{ fontWeight: 'bold', fontSize: 46 }}>
              PALL
            </Text>
          </View>}
        </View>
        <View style={styles.flexInnerRightContainer}>
          <Text style={styles.textContainer}>
            {`Invoice: #${displayNumber}`}
          </Text>
          <Text style={styles.textContainer}>
            {`Created: ${created}`}
          </Text>
          <Text style={styles.textContainer}>
            {`Due: ${due}`}
          </Text>
        </View>
      </View>
      <View style={styles.flexContainer}>
        <View style={styles.flexInnerLeftContainer}>
          <Text style={styles.textContainer}>
            SIA Xpall
            </Text>
          <Text style={styles.textContainer}>
            LV40103770341
          </Text>
          <Text style={styles.textContainer}>
            Lielā iela 71 k-1, Riga, 1016, Latvija
          </Text>
          <Text style={styles.textContainer}>
            Lielā iela 71, Riga, 1016, Latvija
          </Text>
          <Text style={styles.textContainer}>
            Luminor Bank, AS, RIKOLV2X
          </Text>
          <Text style={styles.textContainer}>
            LV60RIKO0002930247290
          </Text>
        </View>
        <View style={styles.flexInnerRightContainer}>
          <Text style={styles.textContainer}>
            {company_name}
          </Text>
          <Text style={styles.textContainer}>
            {company_address}
          </Text>
          <Text style={styles.textContainer}>
            {company_email}
          </Text>
        </View>
      </View>
      {/* <View style={styles.mainTable}>
       <View style={styles.tableHeader}>
          <Text style={styles.tableHeaderText}>
            Transport
            </Text>
          <Text style={styles.tableHeaderText}>
            Price
            </Text>
        </View>
        <View>
          <View style={styles.tableItem}>
            <Text style={styles.tableHeaderText}>
              -item-
              </Text>
            <Text style={styles.tableHeaderText}>
              -Price-
              </Text>
          </View>
          <View style={styles.tableItem}>
            <Text style={styles.tableHeaderText}>
              -item-
              </Text>
            <Text style={styles.tableHeaderText}>
              -Price-
              </Text>
          </View>
        </View>
      </View>*/}
      <View style={styles.mainTable}>
        <View style={styles.tableHeader}>
          <Text style={styles.tableHeaderText}>
            Pallets
            </Text>
          <Text style={styles.tableHeaderText}>
            Price
            </Text>
        </View>
        <View>
          {orderItemsView}

        </View>
      </View>
      <View style={styles.mainTable}>
        <View style={styles.totalHeader}>
          <Text style={styles.tableHeaderText}>
            Total:
            </Text>
          <Text style={styles.tableHeaderText}>
            {totalCost}
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);
/*
Font.register({
  family: 'Artico',
  src: 'http://fonts.cdnfonts.com/css/artico'
});*/

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  flexInnerRightContainer: {
    paddingHorizontal: 5,
    paddingBottom: 20,
    paddingTop: 5,
    display: 'flex',
    alignItems: 'flex-end'
  },
  flexInnerLeftContainer: {
    paddingHorizontal: 5,
    paddingBottom: 20,
    paddingTop: 5,
    display: 'flex',
    //backgroundColor: 'red',
    alignItems: 'flex-start'
  },
  textContainer: {
    color: '#555',
    margin: 2,
    fontSize: 12
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  tableHeaderText: {
    color: '#555',
    margin: 5,
    fontWeight: 900,
    fontSize: 16
  },
  tableText: {
    color: '#555',
    margin: 5,
    fontSize: 16
  },
  mainTable: {
    marginVertical: 10
  },
  tableHeader: {
    backgroundColor: '#EEEEEE',
    borderBottomWidth: 2,
    borderColor: '#FFC000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  totalHeader: {
    backgroundColor: '#EEEEEE',
    borderBottomWidth: 2,
    borderColor: '#555',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  tableItem: {
    //fontFamily: 'Artico',
    fontSize: 10,
    borderBottomWidth: 2,
    borderColor: '#EEEEEE',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});