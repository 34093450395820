import React from "react";
 
import Page from "./Page";
import Auxiliary from "util/Auxiliary";
import {Col, Row} from "antd";
import { connect } from "react-redux";

const SamplePage = (props) => {
  const { isAdmin, myCompanies, loading } = props;
  const companyData = myCompanies?myCompanies[0]:null;
  const pageData = {
    isAdmin,
    loading,
    companyData
  }
  return (
    <div>
      <h2 className="title gx-mb-4">{!loading&&myCompanies&&isAdmin?'Only for users':null}{/*<IntlMessages id="sidebar.samplePage"/>*/}</h2>
      
      <div className="gx-d-flex justify-content-center">
        {/*<h4>Under construction. Here dummy data only!</h4>*/}
      </div>
      <Auxiliary>
        <Row>
          <Col span={24}>
            <Page {...pageData}/>
          </Col>
        </Row>
      </Auxiliary>
    </div>
  );
};

const mapStateToProps = ({/*auth*/firebase: { auth }, firestore: { ordered, data, status: { requesting } }, firestore }) => {
  console.log(firestore);
  const { uid } = auth;
  const { isAdmin } = data;
  const { myCompanies } = ordered;
  //console.log(registrationRequest);
  const loading = uid && requesting.myCompanies && isAdmin;
  return {
    isAdmin,
    myCompanies,
    loading
  }
}
/*
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      requestAccept: RequestsActions.requestAccept,
      requestDelete: RequestsActions.requestDelete
    }, dispatch)
}*/

export default connect(mapStateToProps/*, mapDispatchToProps*/)(SamplePage);