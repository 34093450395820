import React from "react";

import Page from "./Page";
import Auxiliary from "util/Auxiliary";
import {Col, Row} from "antd";

const SamplePage = () => {
  return (
    <div>
      <h2 className="title gx-mb-4">Welcome{/*<IntlMessages id="sidebar.samplePage"/>*/}</h2>
      
      <div className="gx-d-flex justify-content-center">
        {/*<h4>Under construction. Here dummy data only!</h4>*/}
      </div>
      <Auxiliary>
        <Row>
          <Col span={24}>
            {/*<Page/>*/}
          </Col>
        </Row>
      </Auxiliary>
    </div>
  );
};

export default SamplePage;