import React, { useState, useEffect } from 'react';
import { Input, message } from 'antd';

import {
    hideMessage,
    setOrderField
    //acceptRegistrationForm,
    //userTwitterSignIn
} from "appRedux/actions/Orders";
import { useDispatch, useSelector } from "react-redux";
const { Search } = Input;
const key = 'fieldupdater';

const MessageField = (props) => {
    const [messageText, setMessageText] = useState('');
    const dispatch = useDispatch();
    const {  keyId, orderId, value } = props;
    const { loader, alertMessage, showMessage } = useSelector(({ orders }) => orders);
    useEffect(()=>{
        setMessageText(value);
    }, []);
    useEffect(() => {
        //showMessageUi({ type: 'error', content: alertMessage });
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 3000);
        }
    });
    return (
        <>
            <Search
                placeholder="input your comment to customer"
                enterButton="Send"
                size="middle"
                loading={loader}
                disabled={loader}
                //value={messageText}
                onChange={({target: {value}})=>setMessageText(value)
                    /*(obj)=>{
                    console.log(obj.target.value)
                }*/}
                value={messageText}
                onSearch={()=>{
                    console.log(messageText);
                    console.log(orderId, keyId);
                    //message.loading({ content: 'Saving...', key, duration: 0 });
                    dispatch(setOrderField({
                        fieldName: 'sellerComment',
                        fieldValue: messageText,
                        orderId,
                        positionId: keyId

                    }))
                }}
            />
        </>
    )
}

export default MessageField;