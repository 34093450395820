import React, { Fragment, useState, useEffect, useMemo, useCallback, useRef, Component } from "react";
import { Input, Space, Button, message, Alert, Affix, Select, Tag, Tooltip, Modal, Form, Upload, InputNumber, AutoComplete } from "antd";
import ImgCrop from 'antd-img-crop';
import { Loader } from '@googlemaps/js-api-loader';
import { languages, countries } from "countries-list";
import { GoogleApiWrapper } from 'google-maps-react';
import { GoogleComponent } from 'react-google-location'
import { useDispatch, useSelector } from "react-redux";
import firebase from 'firebase';
import axios from 'axios';
//import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import {
    hideMessage,
    addProduct,
    removeProduct,
    editFullProduct
} from "appRedux/actions/Products";
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const quality_legacy = ['New EPAL', 'A (Used White)', 'B (Used Dark)', 'C (Used Very Dark/Low Quality)'];

const MyMapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBd-lhRcOYcG_9JMm1qxJih2tbFVMDN_4g&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
        zoom={12}
        center={props.place ? props.place.geo : { lat: 56.9421829, lng: 24.140986 }}//56.9421829,24.140986
    >
        {props.place && <Marker position={props.place.geo} onClick={props.onMarkerClick} />}
    </GoogleMap>
)
/*
const MyMapComponent = (props) =>
    <MapContainer style={{ height: `400px` }} center={props.place ? [props.place.geo.lat, props.place.geo.lng]  : [ 56.9421829, 24.140986 ]} zoom={12} scrollWheelZoom={true}>
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {props.place ? <Marker position={[props.place.geo.lat, props.place.geo.lng]}>
            <Popup>
                Load place
            </Popup>
        </Marker>:null}
    </MapContainer>

*/

const { Option } = Select;
const FormItem = Form.Item;


const AddProduct = (props) => {
    const dispatch = useDispatch();
    const { myCompanies, item, id, marketSettings } = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const [previewImage, setPreviewImage] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState(null);

    const [fileUID, setFileUID] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [onUploadStart, setOnUploadStart] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [onUploadError, setOnUploadError] = useState(null);
    const [onUploadSuccess, setOnUploadSuccess] = useState(false);
    const [fileURL, setFileURL] = useState(null);

    const [priceField, setPriceField] = useState(item && id ? item.price_EXW_loadplace : 0);
    const [quantityField, setQuantityField] = useState(item && id ? item.quantity : 0);
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);

    const [map, setMap] = useState(null)
    const [position, setPosition] = useState(null)

    const [imagesToDelete, setImagesToDelete] = useState([]);

    const [completeOptions, setCompleteOptions] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState(null);

    const [initialized, setInitialized] = useState(false);

    const markerRef = useRef(null)
    /*const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setPosition(marker.getLatLng())
          }
        },
      }),
      [],
    )*/

    //console.log('/-/*-/-*-*/-*/-*/-*/*-/-/-*/-/*');
    //console.log(item);
    /*const displayMap = useMemo(
        () => (
            <MapContainer
                style={{ height: `400px` }}
                center={[56.9421829, 24.140986]}
                zoom={12}
                scrollWheelZoom={false}
                whenCreated={setMap}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                    ref={markerRef}
                    //eventHandlers={eventHandlers}
                    position={position ? position : [54, 45]}>
                    <Popup>
                        Load place
                    </Popup>
                </Marker>
            </MapContainer>
        ),
        [],
    )*/


    useEffect(() => {
        if (fileUID) {
            console.log(fileList.length);
            setFileList(fileList.map((item) => {
                const { uid } = item;
                if (uid === fileUID)
                    return {
                        ...item,
                        percent: uploadProgress,
                        name: fileName,
                        status: onUploadError
                            ? 'error'
                            : onUploadStart ? 'uploading' : onUploadSuccess ? 'done' : null,
                        url: fileURL
                    }
                else
                    return item;
            }))
        }
        else if (!initialized && item && id) {
            setInitialized(true);
            form.setFieldsValue(item)
            const { place_id, geo, address } = item;
            setSelectedPlace({
                address,
                geo,
                place_id
            })
        }

    }, [fileUID, /*fileList, */uploadProgress, fileName, onUploadError, onUploadStart, onUploadSuccess, fileURL])


    const firebaseUpload = async (data) => {
        //const { firebase } = this.props;
        const { file } = data;
        const newFileName = `${new Date().getTime()}`;
        const ref = firebase
            .storage()
            .ref('images')
            .child(newFileName);

        const task = ref.put(file);
        task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            async snapshot => {
                const progress = Math.floor(
                    (100 * snapshot.bytesTransferred) / snapshot.totalBytes
                );
                setFileUID(file.uid);
                setFileName(newFileName);
                setOnUploadStart(true);
                setUploadProgress(progress);
            },
            error => {
                // Handle error during the upload
                setOnUploadError(error);
                setOnUploadSuccess(false);
            },
            async () => {
                await task.snapshot.ref
                    .getDownloadURL()
                    .then(downloadURL => {
                        setFileURL(downloadURL);
                        setFileUID(file.uid);
                    });
                setOnUploadSuccess(true);
                setOnUploadStart(false);
                setFileUID(null);
                setFileName(null);
            }
        );
    }
    const onPreview = async file => {
        /* if (!file.url/* && !file.preview) {
             file.preview = await getBase64(file.originFileObj);
           }*/
        const { url } = file;
        if (url) {
            setPreviewImage(url);
            setPreviewVisible(true);
            setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
        }
    };
    const onDeleteImage = (imageElm) => {
        const { name, old } = imageElm;
        console.log('DELETE');
        if (!old) {
            firebase
                .storage()
                .ref(`images/${name}`).delete().then(function () {
                    // File deleted successfully
                }).catch(function (error) {
                    // Uh-oh, an error occurred!
                });
        }
        else {
            setImagesToDelete([...imagesToDelete, imageElm])
        }
    }
    const onChangeImage = (args) => {
        console.log(args);
        const { fileList: newFileList } = args;
        setFileList(newFileList);
    };
    const handleOk = async () => {
        setConfirmLoading(true);
        let validation = null;
        try {

            if (item && id) {
                // console.log('edit');

                console.log('///////////////////////////////////////////////');
                form.setFieldsValue({ gallery: fileList });
                console.log(form.getFieldsValue());
                await form.validateFields().then((form_val) => {
                    console.log(form_val);
                    const quality_index = form.getFieldsValue().quality_type;
                    console.log(quality_index);
                    if (marketSettings && marketSettings.PaletteTypes && marketSettings.PaletteTypes[form_val.quality_index]) {
                        console.log(selectedPlace);
                        const { place_id, geo } = selectedPlace ? selectedPlace : item;
                        const fixedFields = {
                            ...form_val,
                            id: item.id,
                            gallery: form_val.gallery.map(({ url, name }) => ({
                                url,
                                name
                            })),
                            quality_type: marketSettings.PaletteTypes[form_val.quality_index],
                            quality: quality_legacy[form_val.quality_index] ? quality_legacy[form_val.quality_index] : "PLEASE UPDATE THE APP",
                            place_id,
                            address: selectedPlace ? selectedPlace.description : item.address,
                            geo
                        }
                        console.log(fixedFields);
                        setModalVisible(false);
                        console.log('do image delete here')
                        imagesToDelete.map((item) => {
                            const { old } = item;
                            if (!old)
                                onDeleteImage(item)
                        })
                        //setFileList([...fileList.filter(({ old }) => old)])
                        dispatch(editFullProduct({
                            ...fixedFields
                        }))
                        setImagesToDelete([]);
                        setInitialized(false);
                        // form.resetFields();
                    }
                });
            }
            else {
                if (selectedPlace) {
                    form.setFieldsValue({ gallery: fileList });
                    //console.log(form.getFieldsValue());
                   // validation = await form.validateFields()
                    await form.validateFields().then((form_val) => {
                        const { place_id, description, geo } = selectedPlace;
                        const fixedFields = {
                            ...form_val,
                            gallery: form_val.gallery.map(({ url, name }) => ({
                                url,
                                name
                            })),
                            quality_type: marketSettings.PaletteTypes[form_val.quality_index],
                            quality: quality_legacy[form_val.quality_index] ? quality_legacy[form_val.quality_index] : "PLEASE UPDATE THE APP",
                            place_id,
                            address: description,
                            geo
                        }
                        //console.log(fixedFields);
                        onFinish(fixedFields);
                        setModalVisible(false);
                        setFileList([]);
                        setImagesToDelete([]);
                        form.resetFields();
                        setInitialized(false);
                    });
                    
                }
                else {
                    message.warning('Please, set the place');
                }
            }
        }
        catch (e) {
            console.log('error');
            console.log(e);
        }
        setConfirmLoading(false);
    };
    //console.log(priceField);
    const handleCancel = () => {
        console.log('Clicked cancel button');
        if (!(item && id)) {
            fileList.map((item) => onDeleteImage(item))
            setFileList([]);
        } else {
            fileList.map((item) => {
                const { old } = item;
                if (!old)
                    onDeleteImage(item)
            })
            setFileList([...fileList.filter(({ old }) => old), ...imagesToDelete])
            setImagesToDelete([]);
        }
        console.log(fileList);
        form.resetFields();
        setModalVisible(false);
        setInitialized(false);
    };
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    const onFinish = values => {
           dispatch(addProduct({
               ...values
           }))
    }

    /*const onMove = useCallback(() => {
            setPosition(map.getCenter())
        }, [map])
    
        const onAction = useCallback((props) => {
            map.setView(props, 12)
        }, [map])
    
        useEffect(() => {
            if (map) {
                map.on('move', onMove)
                return () => {
                    map.off('move', onMove)
                }
            }
        }, [map, onMove])*/
    return (
        <Fragment>
            <Modal
                title="Add new product"
                visible={modalVisible}
                initialValues={{
                    price_EXW_loadplace: 3
                }}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <div
                    style={{ width: '100%' }}>
                    <Form
                        initialValues={{ remember: true }}
                        name="basic"
                        form={form}
                        //onFinish={onFinish}

                        onFinishFailed={onFinishFailed}
                        className="gx-signin-form gx-form-row0">
                        <FormItem label={"Company"} rules={[{ required: true, message: 'Please input company!' }]} name="companyID">
                            <Select
                                showSearch
                                defaultValue={item && id ? item.companyID : null}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>{/*value={selectedCompany} onChange={handleChangeCompany}>*/}
                                {myCompanies.map(item => <Option key={item.id} value={item.id}>{item.company_name}</Option>)}
                            </Select>
                        </FormItem>
                        <FormItem label={"Quality"} rules={[{ required: true, message: 'Please input quality!' }]} name="quality_index">
                            <Select
                                defaultValue={item && id ? item.quality_index : null}
                            >{/*value={selectedCompany} onChange={handleChangeCompany}>*/}
                                {/*<Option value='New (White)'>New (White)</Option>
                            <Option value='A (Light)'>A (Light)</Option>
                            <Option value='B (Dark)'>B (Dark)</Option>
                        <Option value='C (Very Dark)'>C (Very Dark)</Option>*/}
                                {/*<Option value='New EPAL'>New EPAL</Option>
                                <Option value='A (Used White)'>A (Used White)</Option>
                                <Option value='B (Used Dark)'>B (Used Dark)</Option>
                    <Option value='C (Used Very Dark/Low Quality)'>C (Used Very Dark/Low Quality)</Option>*/}
                                {marketSettings ? marketSettings.PaletteTypes.map((typeName, value) => (
                                    <Option key={value} value={value}>{typeName}</Option>
                                )) : []}
                            </Select>
                        </FormItem>
                        <Space size='small' direction='horizontal'>
                            <FormItem label="Price EXW Loading place" rules={[{ required: true, message: 'Please input valid price!' }]} name="price_EXW_loadplace">
                                <InputNumber
                                    defaultValue={item && id ? item.price_EXW_loadplace : null}
                                    formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g)}
                                    //parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={1}
                                    onChange={(value) => setPriceField(value)}
                                />
                            </FormItem>
                            <p>{`Price per 756 truck: ${Math.round((priceField * 75600))/100}`}</p>
                        </Space>
                        <Space size='small' direction='horizontal'>
                            <FormItem label="Quantity" rules={[{ required: true, message: 'Please input valid price!' }]} name="quantity">
                                <InputNumber
                                    min={1}
                                    defaultValue={item && id ? item.quantity : null}
                                    //formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    //onChange={onChange}
                                    onChange={(value) => setQuantityField(value)}
                                />
                            </FormItem>
                            <p>{`Total trucks: ${Math.floor(quantityField / 756)}`}</p>
                        </Space>
                        <FormItem label="Select country" rules={[{ required: true, message: 'Please select country (legal)!' }]} name="country">
                            <Select
                                defaultValue={item && id ? item.country : null}
                                showSearch
                                onChange={() => {
                                    setCompleteOptions([]);
                                    setSelectedPlace(null);
                                }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {Object.values(countries).map(({ native, name }) =>
                                    <Option key={name} value={name}>{`${name} / ${native}`}</Option>
                                )}
                            </Select>
                        </FormItem>

                        <Tooltip placement="top" title='Select country first!'>
                            <FormItem label="Address" rules={[{ required: true, message: 'Please input address!' }]} name="address_string">
                                <AutoComplete

                                    defaultValue={item && id ? item.address_string : null}
                                    options={completeOptions ? completeOptions.map(({ description, place_id }) => ({ value: description, key: place_id })) : []}
                                    onSelect={(item) => {
                                        console.log(item);
                                        const selectedObj = completeOptions.find(({ description }) => description === item);
                                        if (selectedObj) {
                                            const { place_id } = selectedObj;
                                            const geocoder = new window.google.maps.Geocoder();
                                            geocoder.geocode({ placeId: place_id }, (results, status) => {
                                                if (status === "OK") {
                                                    if (results[0]) {
                                                        setSelectedPlace({
                                                            ...selectedObj,
                                                            geo: {
                                                                lat: results[0].geometry.location.lat(),
                                                                lng: results[0].geometry.location.lng()
                                                            }
                                                        })
                                                        //onAction([45, 45]);
                                                        //onAction([results[0].geometry.location.lat(), results[0].geometry.location.lng()])
                                                    } else {
                                                        //window.alert("No results found");
                                                    }
                                                } else {
                                                    window.alert("Geocoder failed due to: " + status);
                                                }
                                            });
                                        }
                                    }}
                                    onSearch={async data => {
                                        const selectedCountryName = form.getFieldsValue()['country']
                                        if (selectedCountryName) {
                                            const selectedCountry = Object.entries(countries).find((item) => selectedCountryName === item[1].name);
                                            if (selectedCountry && window.google.maps.places) {
                                                let autocompleteService = new window.google.maps.places.AutocompleteService();
                                                autocompleteService.getPlacePredictions(
                                                    {
                                                        // ...this.props.searchOptions,
                                                        componentRestrictions: { country: selectedCountry[0].toLowerCase() },
                                                        input: data,
                                                    },
                                                    (result) => {
                                                        setCompleteOptions(result)
                                                    }
                                                );
                                            }
                                        }
                                    }}
                                    placeholder={"Choose country first!"}
                                />
                            </FormItem>
                        </Tooltip>
                        <FormItem>
                            {<MyMapComponent
                                place={selectedPlace}
                                onMarkerClick={() => { }}
                            />}
                            {/*displayMap*/}
                        </FormItem>
                        {/* <FormItem label="Postalcode" rules={[{ required: true, message: 'Please input postalcode!' }]} name="postalcode">
                            <Input />
                            </FormItem>*/}
                        {<FormItem children={fileList.map(({ url }) => url)} label="Gallery" rules={[{ required: true, message: 'Please add some pictures!' }]} name="gallery">

                            <ImgCrop rotate>
                                <Upload
                                    //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    customRequest={firebaseUpload}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChangeImage}
                                    onPreview={onPreview}
                                    onRemove={onDeleteImage}
                                    progress={{
                                        //percent: uploadProgress
                                        strokeColor: {
                                            '0%': ' #FFC000',
                                            '100%': '#fa8c15',
                                        },
                                        // format: percent => `${parseFloat(uploadProgress/*.toFixed(2)*//*)}%`,
                                    }}
                                >
                                    {(fileList.length < 5 && !onUploadStart) && '+ Upload'}
                                </Upload>
                            </ImgCrop>
                        </FormItem>}
                        <FormItem label="Description" rules={[{ required: false, message: 'Please input description' }]} name="description">
                            <Input.TextArea defaultValue={item && id ? item.description : null} rows={4} />
                        </FormItem>
                    </Form>
                </div >
            </Modal>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            <Button type="primary" onClick={() => {
                setModalVisible(true)
                if (item && id)
                    setFileList(item.gallery.map(imageInfo => ({ ...imageInfo, old: true, key: imageInfo.name, uid: imageInfo.name })))
            }}>{(item && id) ? 'Edit' : 'Add'}</Button>
        </Fragment>
    )
}

export default AddProduct;