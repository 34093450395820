import React, { Component, Fragment } from "react";

import {AutoComplete, Button, Input} from "antd";

import Icon from '@ant-design/icons';
import Map from "pigeon-maps";
import Marker from "pigeon-marker";
//import { geolocated } from "react-geolocated";

import {message} from "antd/lib/index";

import axios from 'axios';

const CompleteOption = AutoComplete.Option;
//const CompleteOptGroup = AutoComplete.OptGroup;

class MapPicker extends Component {
    state = {
        center: [56.951, 24.144],
        zoom: 8,
        provider: 'wikimedia',
        metaWheelZoom: false,
        twoFingerDrag: true,
        animate: true,
        animating: false,
        zoomSnap: true,
        mouseEvents: true,
        touchEvents: true,
        minZoom: 1,
        maxZoom: 18,
        geocoded: null,
        geosearch: [],
        dragAnchor: [48.8565, 2.3475],
        marker: null,
        asyncGeoLoading: false
      }
      _isMounted = false;
      componentDidMount() {
        this._isMounted = true;
      }
      
      componentWillUnmount() {
        this._isMounted = false;
      }
      geocodeAutomaticly=async()=>{
        const msgLoading = message.loading('Getting location', 0);
        const geo_success = (position) => {
          msgLoading();
          this.handleClick({latLng:[position.coords.latitude, position.coords.longitude]});
          message.success("You location on the map!");
        }
        
        const geo_error = () => {
          msgLoading();
          message.error("Sorry, no position available.");
        }
        
        await navigator.geolocation.getCurrentPosition(geo_success, geo_error);
      }
    
      selectFromSearch=(place_id)=>{
        const selected = this.state.geosearch.find(item=>item.id===place_id);
        const {marker} = selected;
        if(marker){
          this.handleClick({latLng:[parseFloat(marker[0]), parseFloat(marker[1])], renew: true});
        }
      }
    
      geocodeSearch = (e) => {
        //const {geocoded} = this.state;
        this.setState({ 
          geocoded: e
        });
        if(e.length>3){
          //https://nominatim.openstreetmap.org/?addressdetails=1&q=78+улица+Матиса+Авоты+Рига&format=json&limit=10
          const searchWord = e.replace(' ','+');
          axios.get(`https://nominatim.openstreetmap.org/?addressdetails=1&q=${searchWord}&format=json&limit=10`)
            .then(res => {
              const data = res.data||[];
              const result = data.map(item=>{
                return {
                  id: item.place_id,
                  elm: <CompleteOption 
                          onClick={()=>this.selectFromSearch(item.place_id)}
                          key={item.place_id+item.display_name} 
                          value={item.display_name}>
                        {item.display_name}
                        {/*<span className="certain-search-item-count">{opt.count}</span>*/}
                      </CompleteOption>,
                  marker: [item.lat, item.lon]
                }
              })
              this._isMounted && this.setState({ 
                geosearch: result,
              });
          })
        }
      }
      geocodeReverse = (latlng) => {
        axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latlng[0]}&lon=${latlng[1]}&zoom=18&addressdetails=0`)
          .then(res => {
            const data = res.data;
            const { display_name} = data;
            this._isMounted && this.setState({ 
              geocoded: display_name,
            });
        })
      }
      zoomIn = () => {
        this.setState({
          zoom: Math.min(this.state.zoom + 1, 18)
        })
      }
      zoomOut = () => {
        this.setState({
          zoom: Math.max(this.state.zoom - 1, 1)
        })
      }
      handleBoundsChange = ({ center, zoom, bounds, initial }) => {
   /*if (initial) {
          console.log('Got initial bounds: ', bounds)
        }*/
        this.setState({ center, zoom })
      }
    
      handleClick = ({ event, latLng, pixel, renew }) => {
        !renew&&this.geocodeReverse(latLng);
        this.props.onChange(latLng);
        this.setState({
          marker: <Marker
            anchor={latLng}
            payload={1}
            onClick={({ event, anchor, payload }) => {
              this.setState({
                marker: null,
              })
            }}
          />,
          center: latLng
        });
      }
    
      handleAnimationStart = () => {
        this.setState({ animating: true })
      }
    
      handleAnimationStop = () => {
        this.setState({ animating: false })
      }
    render (){
        const { geocoded, center, zoom, geosearch, animate, metaWheelZoom, twoFingerDrag, zoomSnap, mouseEvents, touchEvents, marker, minZoom, maxZoom } = this.state
        return(
            <Fragment>
                <AutoComplete
                    className="certain-category-search"
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{width: 300}}
                    size="large"
                    style={{width: '100%'}}
                    value={geocoded}
                    onChange={(e)=>this.geocodeSearch(e)}
                    dataSource={this.state.geosearch.map(item=>item.elm)}
                    optionLabelProp="value"
                    placeholder="Pin on map, or enter address"
                >
                    <Input 
                        suffix={<Icon 
                          type="search" 
                          className="certain-category-icon" 
                          onClick={this.geocodeSearch}/>
                    }/>
                </AutoComplete>
                <Button
                    onClick={this.geocodeAutomaticly}>
                    Get my location automaticly
                </Button>
                <Map 
                    limitBounds='edge'
                    center={center}
                    zoom={zoom}
                    dprs={[1, 2]}
                    onBoundsChanged={this.handleBoundsChange}
                    onClick={this.handleClick}
                    onAnimationStart={this.handleAnimationStart}
                    onAnimationStop={this.handleAnimationStop}
                    animate={animate}
                    metaWheelZoom={metaWheelZoom}
                    twoFingerDrag={twoFingerDrag}
                    zoomSnap={zoomSnap}
                    mouseEvents={mouseEvents} 
                    touchEvents={touchEvents}
                    minZoom={minZoom}
                    maxZoom={maxZoom}        
                    defaultWidth={600}
                    height={400}>
                    {marker}
                </Map>
            </Fragment>
            
        )
    }
}

export default MapPicker;