import React, { useRef, useContext, Fragment, useState, useEffect, Component } from "react";
import { Typography, Table, Input, Button, Popconfirm, Form, Row, Col, Space, message, Alert, Affix, Select, Tag, Modal, Upload, InputNumber, AutoComplete } from "antd";
import ImgCrop from 'antd-img-crop';
import NumberFormat from "react-number-format";
import { languages, countries } from "countries-list";

import { useDispatch, useSelector } from "react-redux";
import firebase from 'firebase';
import {
  hideMessage,
  setOrderAdditionalPayment
  //acceptRegistrationForm,
  //userTwitterSignIn
} from "appRedux/actions/Orders";

const key = 'fieldupdater';
const { Option } = Select;
const ReachableContext = React.createContext();
const FormItem = Form.Item;
const Text = Typography;
const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  isNum,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {isNum ? <InputNumber min={0.01} step={0.01} ref={inputRef} onPressEnter={save} onBlur={save} /> : <Input ref={inputRef} onPressEnter={save} onBlur={save} />}
      </Form.Item>
    ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};
const AdditionalFields = (props) => {
  const dispatch = useDispatch();
  const { additionalCosts, keyId, orderId } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { loader, alertMessage, showMessage } = useSelector(({ orders }) => orders);
  const [isEdited, setIsEdited] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
      editable: true,
      isNum: false
    },
    {
      title: 'Summ',
      dataIndex: 'summ',
      editable: true,
      isNum: true
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (text, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];

  const [dataSource, setDataSource] = useState([
    ...additionalCosts
  ]);

  const [count, setCount] = useState(additionalCosts.length);

  const resetFields = () => {
    setDataSource([...additionalCosts]);
    setIsEdited(false);
    setCount(0);
  }

  const handleDelete = key => {
    if (!isEdited) {
      setIsEdited(true);
    }
    const dataSourceTmp = [...dataSource];
    setDataSource(dataSourceTmp.filter(item => item.key !== key));
  };


  const handleAdd = () => {
    if (!isEdited) {
      setIsEdited(true);
    }
    //const { count, dataSource } = this.state;
    const newData = {
      key: count,
      name: `summ ${count}`,
      summ: 32,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  useEffect(() => {
    //showMessageUi({ type: 'error', content: alertMessage });
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
    }
  });
  //IS EDIT _ DIFFERENCE BETWEEN ORIGINAL DATA & USER DATA
  /*useEffect(() => {
    if (additionalCosts === dataSource) {
        if (isEdited) {
            setIsEdited(false);
            message.success({ content: 'Field edited!', key, duration: 2 });
        }
    }
}, [additionalCosts, loader])*/

  const handleSave = row => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };


  const [form] = Form.useForm();

  const handleOk = async () => {
    setConfirmLoading(true);
    let validation = null;
    try {
      //console.log(form.getFieldsValue());
      validation = await form.validateFields();
      /*    const fixedFields = {
              ...validation,
              gallery: validation.gallery.map(({url, name})=>({
                  url,
                  name
              }))
          }
          //console.log(fixedFields);
          onFinish(fixedFields);*/
      setModalVisible(false);
      form.resetFields();
    }
    catch {
      console.log('error');
    }
    setConfirmLoading(false);

  };
  //console.log(priceField);
  const handleCancel = () => {
    console.log('Clicked cancel button');
    //fileList.map((item)=>onDeleteImage(item))
    //console.log(fileList);
    form.resetFields();
    setModalVisible(false);
  };
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  //const { dataSource } = this.state;
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columnsTmp = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        isNum: col.isNum,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });
  return (
    <Fragment>
      <Modal
        title="Edit details"
        visible={modalVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div>
          <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
            Add a row
                    </Button>
          {true/*isEdit */ ? <>
            <Button disabled={/*!isEdited ||*/ loader} onClick={async () => {
              try {
               // message.loading({ content: 'Saving...', key, duration: 0 });
                let validation = null;
                validation = await form.validateFields();
                console.log('apply')
                console.log(dataSource)
                dispatch(setOrderAdditionalPayment({
                  additionalCosts: dataSource,
                  orderId,
                  positionId: keyId
                }))
              }
              catch {
                message.warning('Error! 23');
              }
            }}>Apply</Button>
            <Button disabled={loader} onClick={() => resetFields()}>Reset</Button>
          </> : null}
          <Text style={{ marginBottom: 16 }}>{`Total: ${dataSource.map(({ summ }) => summ).reduce((a, b) => a + b, 0).toFixed(2)}`}</Text>
          <Table

            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={dataSource}
            columns={columnsTmp}
          />
          <Col>
            <Row styles={{ flex: 1, justifyContent: "center" }}>
            </Row>
          </Col>
        </div>
      </Modal>

      <Button type="dashed" onClick={() => setModalVisible(true)}>Details</Button>
    </Fragment>
  )
}

export default AdditionalFields; 