import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import notesSagas from "./Notes";
import companySagas from "./Company";
import productsSagas from "./Products";
import ordersSagas from "./Orders";
import variablesSagas from './Variables';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    notesSagas(),
    companySagas(),
    productsSagas(),
    ordersSagas(),
    variablesSagas()
  ]);
}
