import React, { Fragment, useState, useEffect } from "react";
import { Card, Table, Input, Space, Button, message, Badge, Typography, Alert, Affix, Select, Tag, Spin, Form, InputNumber } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
//import Text from "antd/lib/typography/Text";
import {
    hideMessage,
    setOrderField
    //acceptRegistrationForm,
    //userTwitterSignIn
} from "appRedux/actions/Orders";
import { useDispatch, useSelector } from "react-redux";

const { Text } = Typography;
const { Option } = Select;
const FormItem = Form.Item;
const key = 'fieldupdater';

//part this thing
//
const showMessageUi = ({ type, content }) => {
    message[type](content);
};
//const Context = React.createContext({ name: 'Default' });
const EditField = (props) => {
    const [isEdited, setIsEdited] = useState(false);
    const dispatch = useDispatch();
    const { isAdmin, fieldName, fieldValue, keyId, orderId, step, min, max, disabled } = props;
    const { loader, alertMessage, showMessage } = useSelector(({ orders }) => orders);
    const [form] = Form.useForm();
    //console.log(useSelector(({ company }) => company));
    //console.log('----sdsd----');
    //console.log(fieldValue);
    useEffect(() => {
        //showMessageUi({ type: 'error', content: alertMessage });
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 3000);
        }
    });
    const onEdit = () => {
        if (!isEdited) {
            setIsEdited(true);
        }
    };
    useEffect(() => {
        if (fieldValue === form.getFieldValue([keyId + fieldName])) {
            if (isEdited) {
                setIsEdited(false);
                message.success({ content: 'Field edited!', key, duration: 2 });
            }
        }
    }, [fieldValue, loader])
    //console.log('----------ffffff-f-f-f-f------------f----');
    //console.log(fieldName);
    //console.log(fieldValue);
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            {isAdmin
                ?
               fieldValue!==undefined||fieldValue!==null||isEdited? <Form
                    name={keyId + fieldName}
                    form={form}
                    onFinishFailed={onFinishFailed}
                    className="gx-signin-form gx-form-row0"
                    initialValues={{
                        [keyId + fieldName]: fieldValue,
                    }}
                >
                    <FormItem rules={[{ required: true, message: 'This field is required' }]} name={keyId + fieldName}>
                        <InputNumber
                            disabled={disabled||loader}
                            min={min}
                            step={step}
                            max={max}
                            //formatter={value => {
                            // const parserFunc = () => {
                            /*     const valid = /^\d*\.?(?:\d{1,2})?$/;
                                 let result = `${value}`;
                                 //const text = event.target.textContent;
                                 if (!valid.test(value)) {
                                     return result;
                                     // antd blur();
                                 } else {
                                     return result.replace(/\.$/, '');
                                 }*/
                            //};
                            //console.log('------------------asdasd-aasda-sdas-d-asd------------');
                            ////console.log(value)

                            //return value.replace(/^\d*\.?(?:\d{2,2})?$/);//`${value.replace(/^\d+$/)}`.replace(/\B(?=(\d{3})+(?!\d))/g)
                            // }}
                            //parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={onEdit}
                        />
                    </FormItem>
                    {isEdited ?
                        <>
                            <Button disabled={!isEdited || loader} type="primary" onClick={async () => {
                                try {
                                    message.loading({ content: 'Saving...', key, duration: 0 });
                                    let validation = null;
                                    validation = await form.validateFields();
                                    console.log('apply')
                                    console.log("order", orderId)
                                    console.log("position", keyId)
                                    console.log('field', fieldName);
                                    console.log(validation[keyId + fieldName])
                                    dispatch(setOrderField({
                                        fieldName,
                                        fieldValue: validation[keyId + fieldName],
                                        orderId,
                                        positionId: keyId

                                    }))
                                }
                                catch {
                                    message.warning('Error! 23');
                                }
                            }}>Save</Button>
                            <Button disabled={loader} type='dashed' onClick={() => {
                                setIsEdited(false);
                                form.resetFields();
                            }}>Reset</Button></> : null}
                </Form>:<Button type='dashed' onClick={()=>{setIsEdited(true)}}>Add</Button>

                : null}
        </>
    );
};

export default EditField;