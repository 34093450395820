import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  functions
} from "../../firebase/firebase";
import {
  END_COMPANY_REGISTRATION,
  ACCEPT_REGISTRATION,
  REJECT_REGISTRATION,
  COMPANY_FIELD_CHANGE,
  REJECT_BAN_REGISTRATION
} from "constants/ActionTypes";
import { endRegistration, endRegistrationSuccess, companyFieldChangeSuccess, showMessage } from "../../appRedux/actions/Company";

const acceptHttps = async ({ cloudAction, data }) =>
  await functions.httpsCallable(cloudAction)({
    data
  })
    .then(data => data)
    .catch(error => error);

/*
const sendUpdatedRequestInformation = async (email, password) =>
  await auth.createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);
*/
function* updateRequestInformation({ payload }) {
  /*const { email,
    password,
    company_name,
    company_code,
    VAT_n,
    address_string,
    language,
    country,
    company_URL,
    phone
  } = payload;*/
  try {
    //const signUpUser = yield call(sendUpdatedRequestInformation, email, password);

    /*const data = {
      email,
      company_name,
      company_code,
      VAT_n,
      address_string,
      language,
      country,
      company_URL,
      permitions: 'Seller',
      //phone: phone.code + phone.number
    };*/
    //console.log(data);
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    //yield put(showMessage({message: 'Testing', type: 'warning'}));
    const updateResult = yield call(acceptHttps, { cloudAction: 'update_request', data: payload });
    console.log(updateResult);
    //yield put(userSignUpSuccess(regResult.data.message));
    if (updateResult.data.message === 'ok') {
      //localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(endRegistrationSuccess('Success'));
    }
    else {
      yield put(showMessage({ message: updateResult.data.message, type: 'error' }));
    }

  } catch (error) {
    yield put(showMessage(error));
  }
}
export function* updateRequest() {
  yield takeEvery(END_COMPANY_REGISTRATION, updateRequestInformation);
}

function* acceptCompanyRequest({ payload }) {
  try {
    
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    const updateResult = yield call(acceptHttps, { cloudAction: 'accept_requests_seller', data: payload });
    //console.log(updateResult);
    if( updateResult instanceof Error ){
      yield put(showMessage({ message: 'Connection error', type: 'error' }));
    }
    else{
      const { data: { message } } = updateResult;
      //yield put(userSignUpSuccess(regResult.data.message));
      if (message === 'ok') {
        //localStorage.setItem('user_id', signUpUser.user.uid);
        yield put(endRegistrationSuccess('Success'));
      }
      else {
        yield put(showMessage({ message: message ? message : 'Connection error', type: 'error' }));
      }
    }
  } catch (error) {
    //console.log('!!!!!!!!!!!!!!!!!!!!**!!!!!!!!!')
    yield put(showMessage(error));
  }
}
export function* acceptRequest() {
  yield takeEvery(ACCEPT_REGISTRATION, acceptCompanyRequest);
}
////
function* rejectCompanyRequest({ payload }) {
  try {
    const updateResult = yield call(acceptHttps, { cloudAction: 'reject_requests', data: {...payload, ban: false} });
    if( updateResult instanceof Error ){
      yield put(showMessage({ message: 'Connection error', type: 'error' }));
    }
    else{
      const { data: { message } } = updateResult;
      if (message === 'ok') {
        yield put(endRegistrationSuccess('Success'));
      }
      else {
        yield put(showMessage({ message: message ? message : 'Connection error', type: 'error' }));
      }
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}
export function* rejectRequest() {
  yield takeEvery(REJECT_REGISTRATION, rejectCompanyRequest);
}
function* rejectBanCompanyRequest({ payload }) {
  try {
    const updateResult = yield call(acceptHttps, { cloudAction: 'reject_requests',data: {...payload, ban: true} });
    if( updateResult instanceof Error ){
      yield put(showMessage({ message: 'Connection error', type: 'error' }));
    }
    else{
      const { data: { message } } = updateResult;
      if (message === 'ok') {
        yield put(endRegistrationSuccess('Success'));
      }
      else {
        yield put(showMessage({ message: message ? message : 'Connection error', type: 'error' }));
      }
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}
export function* rejectBanRequest() {
  yield takeEvery(REJECT_BAN_REGISTRATION, rejectBanCompanyRequest);
}

function* setCompanyFieldAction({ payload }) {
  try {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    //yield put(showMessage({message: 'Testing', type: 'warning'}));
    const setStatusResult = yield call(acceptHttps, { cloudAction: 'update_company_field_admin', data: payload });
    console.log(setStatusResult);
    if (setStatusResult.data.message === 'ok') {
      yield put(companyFieldChangeSuccess('Success'));
    }
    else {
      yield put(showMessage({ message: setStatusResult.data.message, type: 'error' }));
    }

  } catch (error) {
    yield put(showMessage(error));
  }
}
export function* setCompanyField() {
  yield takeEvery(COMPANY_FIELD_CHANGE, setCompanyFieldAction);
}

export default function* rootSaga() {
  yield all([
    fork(updateRequest),
    fork(acceptRequest),
    fork(rejectRequest),
    fork(rejectBanRequest),
    fork(setCompanyField)
  ]);
}
