import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  functions
} from "../../firebase/firebase";
import {
  ORDER_STATUS_CHANGE_SUCCESS,
  ORDER_STATUS_CHANGE,
  ORDER_FIELD_CHANGE_SUCCESS,
  ORDER_INVOICE_CHANGE,
  ORDER_INVOICE_CHANGE_SUCCESS,
  ORDER_FIELD_CHANGE,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
} from "constants/ActionTypes";
import { setOrderStatusSuccess,setOrderFieldSuccess, showMessage, removeOrderSuccess } from "../../appRedux/actions/Orders";
import { ORDER_ADDITIONAL_PAYMENT_CHANGE } from "../../constants/ActionTypes";

const acceptHttps = async ({ cloudAction, data }) =>
  await functions.httpsCallable(cloudAction)({
    data
  })
    .then(data => data)
    .catch(error => error);

/*
const sendUpdatedRequestInformation = async (email, password) =>
  await auth.createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);
*/
function* setOrderInvoiceAction({ payload }) {
  try {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    //yield put(showMessage({message: 'Testing', type: 'warning'}));
    const setStatusResult = yield call(acceptHttps, { cloudAction: 'update_order_invoice', data: payload });
    console.log(setStatusResult);
    
    if (setStatusResult.data.message === 'ok') {
      yield put(setOrderStatusSuccess('Success'));
    }
    else {
      yield put(showMessage({ message: setStatusResult.data.message, type: 'error' }));
    }

  } catch (error) {
    yield put(showMessage(error));
  }
}
export function* setOrderInvoice() {
  yield takeEvery(ORDER_INVOICE_CHANGE, setOrderInvoiceAction);
}
function* setOrderStatusAction({ payload }) {
  try {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    //yield put(showMessage({message: 'Testing', type: 'warning'}));
    const setStatusResult = yield call(acceptHttps, { cloudAction: 'update_order_status', data: payload });
    console.log(setStatusResult);
    
    if (setStatusResult.data.message === 'ok') {
      yield put(setOrderStatusSuccess('Success'));
    }
    else {
      yield put(showMessage({ message: setStatusResult.data.message, type: 'error' }));
    }

  } catch (error) {
    yield put(showMessage(error));
  }
}
export function* setOrderStatus() {
  yield takeEvery(ORDER_STATUS_CHANGE, setOrderStatusAction);
}
function* setOrderFieldAction({ payload }) {
  try {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    //yield put(showMessage({message: 'Testing', type: 'warning'}));
    const setStatusResult = yield call(acceptHttps, { cloudAction: 'update_order_field_admin', data: payload });
    console.log(setStatusResult);
    if (setStatusResult.data.message === 'ok') {
      yield put(setOrderFieldSuccess('Success'));
    }
    else {
      yield put(showMessage({ message: setStatusResult.data.message, type: 'error' }));
    }

  } catch (error) {
    yield put(showMessage(error));
  }
}
export function* setOrderField() {
  yield takeEvery(ORDER_FIELD_CHANGE, setOrderFieldAction);
}

function* setOrderAdditionalPaymentAction({ payload }) {
  try {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    //yield put(showMessage({message: 'Testing', type: 'warning'}));
    const setStatusResult = yield call(acceptHttps, { cloudAction: 'update_order_additional_payment_admin', data: payload });
    console.log(setStatusResult);
    if (setStatusResult.data.message === 'ok') {
      yield put(setOrderStatusSuccess('Success'));
    }
    else {
      yield put(showMessage({ message: setStatusResult.data.message, type: 'error' }));
    }

  } catch (error) {
    yield put(showMessage(error));
  }
}
export function* setOrderAdditionalPayment() {
  yield takeEvery(ORDER_ADDITIONAL_PAYMENT_CHANGE, setOrderAdditionalPaymentAction);
}
function* deleteOrderAction({ payload }) {
  //console.log(payload);
  
  try {
    const updateResult = yield call(acceptHttps, { cloudAction: 'delete_order', data: payload });
    //console.log(updateResult);
    if( updateResult instanceof Error ){
      yield put(showMessage({ message: 'Connection error', type: 'error' }));
    }
    else{
      const { data: { message } } = updateResult;
      if (message === 'ok') {
        yield put(removeOrderSuccess('Success'));
      }
      else {
        yield put(showMessage({ message: message ? message : 'Connection error', type: 'error' }));
      }
    }
  } catch (error) {
    //console.log('!!!!!!!!!!!!!!!!!!!!**!!!!!!!!!')
    yield put(showMessage(error));
  }
}
export function* deleteOrder() {
  yield takeEvery(DELETE_ORDER, deleteOrderAction);
}

export default function* rootSaga() {
  yield all([
    fork(deleteOrder),
    fork(setOrderInvoice),
    fork(setOrderStatus),
    fork(setOrderField),
    fork(setOrderAdditionalPayment)
  ]);
}
